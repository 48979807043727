import React from "react";
import { Link } from "react-router-dom";
import "./HomeNavbar.css";
import useAuth from "./hooks/useAuth.js";
import useLogout from "./hooks/useLogout.js";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MARTSDB from "./MARTS-DB.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import downloadDatabase from "./api/downloadDatabase.js";
import JSZip from "jszip";
import NavBarButton from "./Components/NavBarButton.js";


function HomeNavbar({
  suggestionCount,
  reloadAdvanced,
  reloadQuick
}) {
    
  
  const [mouseOverSuggestion, setMouseOverSuggestion] = useState(false);
  const [mouseOverStat, setMouseOverStat] = useState(false);
  const [mouseOverDownload, setMouseOverDownload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const logout = useLogout();
  const { auth } = useAuth();
  const navigate = useNavigate()
  const location = useLocation()

  async function downloadData() {
    setIsDownloading(true);
    const result = await downloadDatabase();
    const zip = new JSZip()
    zip.file("martsReactionsData.csv", result[0])
    zip.file("martsMechanismData.csv", result[1])
    zip.file("martsPersistentIdData.csv", result[2])
    zip.file("martsHistoricNoteData.csv", result[3])
    zip.generateAsync({ type: "blob" })
          .then(function(content) {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(content);
            a.download = "martsDb.zip";
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch(function(err) {
            console.error("Error creating ZIP file:", err);
          });
    setIsDownloading(false);
  }

  function dropdown(id) {
    const dropdown = document.getElementById(id);
    dropdown?.classList?.add("displayed");
    if (dropdown.style.display === "none") {
      dropdown.style.display = "block";
    } else {
      dropdown.style.display = "none";
    }
  }

  window.onclick = function (event) {
    if (
      !(
        event.target.matches(".dropbtn") ||
        event.target.matches("#SuggestionButton") ||
        event.target.matches("#CommentButton")
      )
    ) {
      const dropdown = document.getElementById("dropdownProfile");
      if (dropdown?.classList?.contains("displayed")) {
        dropdown?.classList?.remove("displayed");
        dropdown.style.display = "none";
      }
    }
    if (!event.target.matches(".dropbtnsrch")) {
      const search = document.getElementById("dropDownSearch");
      if (search?.classList?.contains("displayed")) {
        search?.classList?.remove("displayed");
        search.style.display = "none";
      }
    }
    if (!event.target.matches(".dropbtnsggst")) {
      const suggest = document.getElementById("dropDownSuggest");
      if (suggest?.classList?.contains("displayed")) {
        suggest?.classList?.remove("displayed");
        suggest.style.display = "none";
      }
    }
    if (!event.target.matches(".dropbtnntr")) {
      const entry = document.getElementById("dropDownEntry");
      if (entry?.classList?.contains("displayed")) {
        entry?.classList?.remove("displayed");
        entry.style.display = "none";
      }
    }
    if (
      !(
        event.target.matches(".suggestMechanism") ||
        event.target.matches("#faCommentMechanism") ||
        event.target.matches(".suggestDiv") ||
        event.target.matches(".suggestInput") ||
        event.target.matches(".confirmButton") ||
        event.target.matches(".innerTextSubmit")
      )
    ) {
      for (let i = 0; i < suggestionCount; i++) {
        if (document.getElementById(`suggestDiv${i}`)) {
          if (
            document.getElementById(`suggestDiv${i}`).style.display == "block"
          ) {
            document.getElementById(`suggestDiv${i}`).style.display = "none";
            document.getElementById(`suggestInput${i}`).value = "";
          }
        }
      }
    }
    if (
      !(
        event.target.matches(".suggestNote") ||
        event.target.matches("#faCommentNote") ||
        event.target.matches(".suggestDivNote") ||
        event.target.matches(".suggestInputNote") ||
        event.target.matches(".confirmButtonNote") ||
        event.target.matches(".innerTextSubmitNote")
      )
    ) {
      for (let i = 0; i < suggestionCount; i++) {
        if (document.getElementById(`suggestDivNote${i}`)) {
          if (
            document.getElementById(`suggestDivNote${i}`).style.display ==
            "block"
          ) {
            document.getElementById(`suggestDivNote${i}`).style.display =
              "none";
            document.getElementById(`suggestInputNote${i}`).value = "";
          }
        }
      }
    }
  };

  const user = [2001, 5150, 1984];
  const editor = [1984, 5150];
  const admin = [5150];

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
        />
      </head>
      <body>
        <nav id="HomeNavbar">
          <ul id="UL">
            <li id="HomeLi">
              <button
                style={{
                  padding: 0,
                  margin: 0,
                  backgroundColor: "rgb(23,55,151)",
                  color: "white",
                  border: "none",
                }}
                id="homePage"
                onClick={()=>{navigate("/");
                  if (location.pathname === "/") {
                    reloadQuick()
                  }
                }}
              >
                <img
                  src={MARTSDB}
                  alt="Home"
                  style={{
                    width: "inherit",
                    height: "inherit",
                    marginTop: "-17.5px",
                  }}
                ></img>
              </button>
            </li>
            {/*<li id="LoginLi">
              <nobr>
                <Link
                  id="login"
                  to="/login"
                  style={
                    !auth?.accessToken
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  Log in
                </Link>
              </nobr>
            </li>*/}
          </ul>
          <ul id="ul">
            <NavBarButton text="Advanced Search" tip="Select multiple filters at once and search for a reaction or its mechanism" route="/advancedsearch"/>
            <NavBarButton text="Submit" tip="Submit new entry for the database" route="/submit"/>
            <NavBarButton text="Statistics" tip="Statistics for the current release of MartsDB" route="/statistics"/>
            {!isDownloading ? (<NavBarButton text="Download" tip="Download the entire database in csv format" func={downloadData} icon={faDownload}/>) : (
              <NavBarButton text="Download" tip="Download the entire database in csv format" func={() => {}} icon={faHourglassHalf}/>)}
            <NavBarButton text="Help" tip="Read how to use MARTS and what are terpene synthases" route="/aboutpage"/>
            <li style={{ position: "relative", paddingTop: 13, paddingBottom: 11 }} id="profileList">
              <nobr>
                <button
                  id="profileButton"
                  className="dropbtn"
                  onClick={() => dropdown("dropdownProfile")}
                  style={
                    auth?.accessToken
                      ? { display: "block"}
                      : { display: "none"}
                  }
                >
                  <FontAwesomeIcon icon={faUser} /> {auth.username}
                </button>
              </nobr>
              <div
                id="dropdownProfile"
                className="dropdiv"
                style={{ display: "none", color: "white" }}
              >
                <ul id="dropdownUl" style={{ color: "white" }}>
                  {editor.includes(auth?.roles) ? (
                    <>
                      <li id="dropdownList">
                        <button
                          id="SuggestionButton"
                          className="dropdownButton"
                          onClick={() => {
                            if (
                              document.getElementById("submenuSuggestion").style
                                .display === "none"
                            ) {
                              document.getElementById(
                                "submenuSuggestion"
                              ).style.display = "block";
                            } else {
                              document.getElementById(
                                "submenuSuggestion"
                              ).style.display = "none";
                            }
                          }}
                        >
                          Suggestions
                        </button>
                      </li>
                      <div
                        id="submenuSuggestion"
                        style={{
                          display: "none",
                          borderTop: "1px lightgrey solid",
                        }}
                      >
                        <li style={{ marginLeft: 10 }}>
                          <Link id="MyEntries" to="/mysuggestions">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color="white"
                              style={{ fontSize: 12 }}
                            />{" "}
                            My Suggestions
                          </Link>
                        </li>
                        <li style={{ marginLeft: 10 }}>
                          <Link id="MyEntries" to="/suggestions">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color="white"
                              style={{ fontSize: 12 }}
                            />{" "}
                            All Suggestions
                          </Link>
                        </li>
                      </div>
                    </>
                  ) : (
                    <li id="dropdownList">
                      <button
                        id="suggestionButton"
                        className="dropdownButton"
                        onClick={() => navigate("/mysuggestions")}
                      >
                        My suggestions
                      </button>
                    </li>
                  )}
                  {editor.includes(auth?.roles) ? (
                    <>
                      <li id="dropdownList">
                        <button
                          id="CommentButton"
                          className="dropdownButton"
                          onClick={() => {
                            if (
                              document.getElementById("submenuComment").style
                                .display === "none"
                            ) {
                              document.getElementById(
                                "submenuComment"
                              ).style.display = "block";
                            } else {
                              document.getElementById(
                                "submenuComment"
                              ).style.display = "none";
                            }
                          }}
                        >
                          Comments
                        </button>
                      </li>
                      <div
                        id="submenuComment"
                        style={{
                          display: "none",
                          borderTop: "1px lightgrey solid",
                        }}
                      >
                        <li style={{ marginLeft: 10 }}>
                          <Link id="MyEntries" to="/mycomments">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color="white"
                              style={{ fontSize: 12 }}
                            />{" "}
                            My Comments
                          </Link>
                        </li>
                        <li style={{ marginLeft: 10 }}>
                          <Link id="MyEntries" to="/comments">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color="white"
                              style={{ fontSize: 12 }}
                            />{" "}
                            All Comments
                          </Link>
                        </li>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <li id="dropdownList">
                    <button
                      id="Logout"
                      className="dropdownButton"
                      onClick={logout}
                    >
                      Log out
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </body>
    </html>
  );
}

export default HomeNavbar;
