import React from "react";
import ExternalLink from "../ReactionTable/externalLink.js";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

// This component is used to display text in the ResultsTable it can be clicked and the serves as a on_click
function ResultInfoText({ text, link = null, onClick = null, linkText = "Find in external source", row, fontSize="16px", buttonClass="plane-text-button", labelText ="" }) {
    return (
        <>
        {labelText != "" && (<label style={{fontSize: fontSize, marginRight:"5px"}}>{labelText} </label>) } 
        {link && (
            <ExternalLink
                idLink={`link${text}${row}`}
                idDiv={`div${text}${row}`}
                icon={faExternalLink}
                externalLink={link}
            >
                {linkText}
            </ExternalLink>
        )}
        {onClick ? (
        <button
            class={buttonClass}
            style={{fontSize: fontSize}}
            disabled={onClick ? false : true}
            onClick={() => onClick(text)}
        >
            {text}
        </button>) : <label class="plane-text-label" style={{fontSize: fontSize}} disabled={true}>{text}</label>}
        </>
    );
}

export default ResultInfoText;