import SeeMechanismCheckbox from "./seeMechanismCheckbox.js";

function SearchClearMechanismButtons({handleSearch,handleClear,handleToggleMechanism,mechanismUnclicked,isSuggestion}) {
  return (
    <ul
      style={{
        listStyle: "none",
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <li>
        <button
          class="basic-button"
          onClick={handleSearch}
        >
          Search
        </button>
        <button
          class="basic-button"
          onClick={handleClear}
        >
          Clear all
        </button>
      </li>
      <li>
        {isSuggestion ? (<></>) : (<>
          <SeeMechanismCheckbox
          handleToggleMechanism={handleToggleMechanism}
          mechanismUnclicked={mechanismUnclicked}
        />
        </>)}
        
      </li>
    </ul>
  );
}

export default SearchClearMechanismButtons;
