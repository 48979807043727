function groupKingdoms(results) {
    const kingdomMap = {};
    
    results.forEach(({ kingdom, occurrence_count }) => {
        // Extract the main kingdom name by removing everything in parentheses
        const mainKingdom = kingdom.replace(/\s*\(.*?\)\s*/g, '');
        
        // Sum occurrence counts for each kingdom
        if (!kingdomMap[mainKingdom]) {
            kingdomMap[mainKingdom] = 0;
        }
        kingdomMap[mainKingdom] += occurrence_count;
    });
    
    // Convert the map back into an array of objects
    const res = Object.entries(kingdomMap).map(([kingdom, occurrence_count]) => ({
        kingdom,
        occurrence_count
    }));
    return res.sort((a, b) => b.occurrence_count - a.occurrence_count);
}


function groupTypesAndKingdoms(results){
    const customOrder = ['hemi', 'mono', 'sesq', 'di', 'sester', 'tri', 'sesquar', 'tetra', 'pt', 'ids']
    const dictByType = {};
    const groups = []
    results.forEach(({type, kingdom, occurrence_count}) => {
        if (!dictByType[type]) {
            dictByType[type] = {};
        }
        const mainKingdom = kingdom.replace(/\s*\(.*?\)\s*/g, '');
        if (!groups.includes(mainKingdom)) {
            groups.push(mainKingdom);
        }
        if (!dictByType[type][mainKingdom]) {
            dictByType[type][mainKingdom] = 0;
        }
        dictByType[type][mainKingdom] += occurrence_count;
    })
    const res = [];
    Object.keys(dictByType).forEach((type) => {
        const obj = {type: type};
        const mergedObject = Object.assign({}, obj, dictByType[type]);
        res.push(mergedObject);
    });
    const sortedRes = res.sort((a, b) => {
        const indexA = customOrder.indexOf(a.type);
        const indexB = customOrder.indexOf(b.type);
        return indexA - indexB;
      });
    const completeData = sortedRes.map(entry => {
        let newEntry = { ...entry };
        groups.forEach(group => {
          if (!(group in newEntry)) {
            newEntry[group] = 0;
          }
        });
        return newEntry;
      });
    return [completeData, groups];
}

function calculateAminoAcidFrequency(sequences){  
const frequency = {};
sequences.forEach(s => {
    if (s.aminoacid_sequence.includes("_")) {
        console.log(s)
    }
});

sequences.map(s => s.aminoacid_sequence).join("").split("").forEach(aa => {
    frequency[aa] = (frequency[aa] || 0) + 1;
});
delete frequency.X;
delete frequency["*"];
const orderedAA = Object.entries(frequency).sort((a, b) => b[1] - a[1]).map(([aa, freq]) => ({ aa, freq }))
return orderedAA;
}

export { groupKingdoms, groupTypesAndKingdoms, calculateAminoAcidFrequency };