import Creatable,  { useCreatable } from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';
import React, { Component } from "react";

function SubmitCreatableSelect({ label, options, onChange, placeholder, target, value }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}><span className="input-field-submit-label">{label}</span>
          <CreatableSelect id="SomethinKingdom" true options={options} onChange={(e) => (e ? onChange({target:{name:target, value:e.value}}) : "")} placeholder={placeholder} 
             styles={{
              control: (base) => ({
                ...base,
                display: 'inline-flex', 
                width: 'auto', 
                minWidth: '200px',
                maxWidth: '200px',
              }),
            }}/>
    </div>
  );
}

export default SubmitCreatableSelect;