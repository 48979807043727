import InformationPopup from "./informationPopup.js";

function IconButton({
  children,
  kingdom,
  species,
  disabledCondition,
  handleOnClick,
  row,
  backgroundColor="rgb(245,245,245)"
}) {
  function handleMouseEnter(idDiv) {
    const popupElement = document.getElementById(idDiv);
    popupElement.style.display = "block";
  }

  function handleMouseLeave(idDiv) {
    const popupElement = document.getElementById(idDiv);
    popupElement.style.display = "none";
  }

  return (
    <nobr
      style={{ position: "relative" }}
      onMouseEnter={() => handleMouseEnter(`speciesDiv${row}`)}
      onMouseLeave={() => handleMouseLeave(`speciesDiv${row}`)}
    >
      <button
        style={{ border: "none", backgroundColor: backgroundColor }}
        id="seeSpeciesDiv"
        disabled={disabledCondition}
        onClick={handleOnClick}
      >
        {children}
      </button>
      <InformationPopup
        id={`speciesDiv${row}`}
        className="speciesDiv"
        style={{ right: 0, left: 0 }}
      >
        {kingdom}: {species}
      </InformationPopup>
    </nobr>
  );
}

export default IconButton;
