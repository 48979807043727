import UniprotLink from "../Components/ReactionTable/uniprotLink.js";
import GenbankLink from "../Components/ReactionTable/genbankLink.js";
import SubstrateRow from "../Components/ReactionTable/substrateRow.js";
import ProductRow from "../Components/ReactionTable/productRow.js";
import KingdomIcon from "../Components/ReactionTable/kingdomIcon.js";
import CopySequence from "../Components/ReactionTable/copySequence.js";
import TypeClass from "../Components/ReactionTable/typeClass.js";
import CosubstrateRow from "../Components/ReactionTable/cosubstrateRow.js";
import InteractiveImage from "../Components/ReactionTable/interactiveImage.js";
import renderMechanism from "./renderMechanism.js";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import ReactionLink from "../Components/ReactionTable/reactionLink.js";
import MechanismEvidence from "../Components/ReactionTable/mechanismEvidence.js";
import ExternalLink from "../Components/ReactionTable/externalLink.js";
import EnzymeResultBox from "../Components/ResultsTable/EnzymeResultBox.js";
import getDataByEnzymesFromSearchResults from "./transformSearchResultsToEnzymes.js";

function renderSearchResults({
  table,
  interactiveSearch,
  setMechBody,
  isQuickSearch,
  interactiveAdvancedSearch,
  showEnzymePopups,
  showMechanismPopups,
  showProductPopups,
  showSubstratePopups,
  mechanismUnclicked,
  dataProcessed = false,
  navigateToRecord
}){

    console.log(table)
    let processedResults = table
    if(!dataProcessed){
     processedResults = getDataByEnzymesFromSearchResults(table)
    }
    console.log(processedResults)
    const res = processedResults.map((row, index) => (
        <EnzymeResultBox table={processedResults} row={index} interactiveSearch={interactiveSearch} setMechBody={setMechBody} navigateToRecord={navigateToRecord}/>
    ))
    console.log(res)
    return res

}

export default renderSearchResults