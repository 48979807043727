import MoleculeForm from "./MoleculeForm.js"; // Import MoleculeForm component
import StatsBox from "../StatsPage/StatsBox.js";
import SubmitInputField from "./submitInputField.js";
import { useState } from "react";
import axios from "../../api/axios.js";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import MechanismSelectionButton from "./MechanismSelectionButton.js";
import renderMechanism from "../../api/renderMechanism.js";
import MechanismForm from "./MechanismForm.js";


export default function ReactionForm({ reaction, onChange, setMechanismBody, mechanismTypes, evidenceTypes }) {

  const [mechanismSelection, setMechanismSelection] = useState([]);
  const [mechanismMessage, setMechanismMessage] = useState("");
  const [definingMechanism, setDefiningMechanism] = useState(false);
  
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    onChange({ ...reaction, [name]: value });
  };

  const handleMoleculeChange = (index, updatedMolecule, type) => {
    if (type === "substrate") {
      onChange({ ...reaction, substrates: reaction.substrates.map((s, i) => (i === index ? updatedMolecule : s)) });
    } else {
      onChange({ ...reaction, product_mol: updatedMolecule });
    }
  };

  const addSubstrate = () => {
    onChange({ ...reaction, substrates: [...reaction.substrates, { molecule_id: null, smiles: "", name: "", marts: "", chebi: "" }] });
  }
  const removeLastSubstrate = () => {
    if (reaction.substrates.length === 0) {
      return;
    }
    onChange({ ...reaction, substrates: reaction.substrates.slice(0, -1) });
  }

  const handleMechanismChange = (mechanismSteps) => {
    onChange({ ...reaction, mechanism: mechanismSteps });
  };

  const finalMechanismStepChange = (field, value) => {
    onChange({ ...reaction, [field]: value });
  }


  async function selectKnownMechanism() {
    let allMoleculesHaveMarts = true;
    reaction.substrates.forEach((substrate) => {
      if (!/^marts_[ERM][0-9]{5}$/.test(substrate.marts)) {
        allMoleculesHaveMarts = false;
      }
    });
    if (!/^marts_[ERM][0-9]{5}$/.test(reaction.product_mol.marts)) {
      allMoleculesHaveMarts = false;
    }
    if (!allMoleculesHaveMarts) {
      setMechanismMessage("All molecules must exist in the database to select a known mechanism");
      return;
    }
    const req = { 
      substrates: reaction.substrates.map((substrate) => substrate.marts),
      product: reaction.product_mol.marts,
      requestType: "mechanism"
    }
    const response = await axios.post("/submitcheck", req)
    console.log(response.data);
    if (typeof response.data === 'object' && response.data){
     
      setMechanismSelection(response.data);
    }
    else{
      setMechanismMessage("No known mechanisms");
    }
  }

  const selectMechanism = (id) => {
    setMechanismMessage("Mechanism " + id +  " selected");
    onChange({ ...reaction, mechanism_marts: id });
  }

  async function renderSelectedMechanism(marts_id){
    console.log(marts_id);
      const res = await renderMechanism({table: null, row: null, isQuickSearch: false, showPopups: false, mechanismUnclicked: false, mechanism_marts_id: marts_id, setMechBody: setMechanismBody});
  }

  const defineMechanism = () => {
    setDefiningMechanism(true);
  }
  return (

    <div>
        <StatsBox title="Reaction">
        {reaction.is_new ? (
          <p>Define substrate, product and additional information for your reaction.</p>
        ) : (
          <p>This reaction is from the database. You can define a new mechanism for it.</p>
        )}
        <SubmitInputField name="type" value={reaction.type} onChange={handleFieldChange} label="Type" disabled={!reaction.is_new} tooltip="The terpene type of the reaction. Use the type prefix e.g. mono for monoterpenes. Isoprenyl diphosphate synthases and prenyl transferases should use pt as type." placeholder="di"/>
        <SubmitInputField name="class" value={reaction.class} onChange={handleFieldChange} label="Class" disabled={!reaction.is_new} tooltip="Write the class in arabic numbers" placeholder="1" />
        <SubmitInputField name="publication" value={reaction.reaction_link} onChange={handleFieldChange} label="Publication link" disabled={!reaction.is_new} tooltip="Enter a link to a paper or other source for this reaction. " placeholder="https://www.nature.com/articles/s41589-022-01026-2"/>
        <SubmitInputField name="publicationdoi" value={reaction.reaction_doi} onChange={handleFieldChange} label="Publication DOI" disabled={!reaction.is_new} tooltip="Enter the DOI of the source of this reaction. This is the prefered way of entering source." placeholder="https://doi.org/10.1038/s41589-022-01026-2"/>
        Mechanism: {reaction.mechanism_marts}
      {reaction.substrates.map((substrate, index) => (
        <MoleculeForm
          key={index}
          title_text={`Substrate ${index + 1}`}
          molecule={substrate}
          onChange={(updatedMolecule) => handleMoleculeChange(index, updatedMolecule, "substrate")}
          disabled={!reaction.is_new}
          moleculeId_start={substrate.molecule_id}
        />
      ))}
        <button
          class = "basic-button"
          onClick={addSubstrate}
          disabled={!reaction.is_new}
          >
          Add Substrate
        </button>
        <button
          class = "basic-button"
          onClick={removeLastSubstrate}
          disabled={!reaction.is_new}
          >
          Remove Substrate
        </button>
        <div style={{ height: "30px" }}></div>
      <MoleculeForm
      key = {0}
        molecule={reaction.product_mol}
        onChange={(updatedMolecule) => handleMoleculeChange(0, updatedMolecule, "product")}
        title_text={"Product"}
        disabled={!reaction.is_new}
        moleculeId_start={reaction.product_mol.molecule_id}
      />
          <button
          class = "basic-button"
          onClick={selectKnownMechanism}
          >
          Select Known Mechanism
        </button>
        <button
          class = "basic-button"
          onClick={defineMechanism}
          >
          Define Mechanism
        </button>
        {mechanismMessage}
      <div>
      {mechanismSelection.map((mechanism, index) => (
        <MechanismSelectionButton key={index} marts_id={mechanism.marts_id} onClick={selectMechanism} renderFunction={renderSelectedMechanism}  />
      ))}
      {definingMechanism && (<MechanismForm substrate={reaction.substrates[0]} product={reaction.product_mol} onChange={handleMechanismChange} mechanismTypes={mechanismTypes} final_step_change={finalMechanismStepChange} evidenceTypes={evidenceTypes}/>)}


      </div>
      </StatsBox>
      </div>
  );
}