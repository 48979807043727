import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ExternalLink({ idLink, idDiv="defaulIdDiv", externalLink, children, icon }) {

  function handleMouseEnter(idDiv) {
    const popupElement = document.getElementById(idDiv)
    popupElement.style.display = "block";
  }

  function handleMouseLeave(idDiv) {
    const popupElement = document.getElementById(idDiv)
    popupElement.style.display = "none";
  }

  return (
    <a
      onMouseEnter={() => handleMouseEnter(idDiv)}
      onMouseLeave={() => handleMouseLeave(idDiv)}
      style={{ position: "relative" }}
      id={idLink}
      href={externalLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={icon} color="rgb(0,0,0,0.8)" />
      <div id={idDiv} className="externalLink" style={{ width: "max-content" }}>
        {children}
      </div>
    </a>
  );
}

export default ExternalLink;
