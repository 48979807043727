import React, { Component } from "react";
import StatsBox from "../Components/StatsPage/StatsBox.js";
import "./BasicPage.css";
import "./SubmitPage.css";
import axios from "../api/axios.js";
import Footer from "../Components/Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import { useState, useRef, useEffect } from "react";
import SubmitInputField from "../Components/SubmitPage/submitInputField.js";
import ReactionForm from "../Components/SubmitPage/ReactionForm.js";
import groupSubstrates from "../functions/groupSubstratesSubmit.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MechanismWindow from "../Components/MechanismTable/mechanismWindow.js";
import { renderMechanism } from "../api/renderMechanism.js";
import MechanismTable from "../mechanismTable.js";
import Creatable,  { useCreatable } from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';
import SubmitCreatableSelect from "../Components/SubmitPage/SubmitCreatableSelect.js";
import { useNavigate } from "react-router-dom";

function SubmitPage() {

    const [formEnzymeData, setFormData] = useState({
        uniprotId: "",
        genbankId: "",
        martsId: "",
        enzymeName: "",
        aminoacidSequence: "",
        species: "",
        kingdom: "" 
      });
      const navigate = useNavigate();
      const isAutoFilling = useRef(false);
      const [isAutoFilled, setIsAutoFilled] = useState(false);
      const [reactions, setReactions] = useState([]);
      const [SubmitMessage, setSubmitMessage] = useState("");
      const [mechanismBody, setMechanismBody] = useState([]);
      const [mechanismStepTypes, setMechanismStepTypes] = useState([]);
      const [kingdoms, setKingdoms] = useState([]);
      const [evidenceTypes, setEvidenceTypes] = useState([]);

      async function getIntitalCheck(){
        const req = {
          requestType: "initialCheck"
        }
        const response = await axios.post("/submitcheck", req);
        setMechanismStepTypes(response.data.mechanism_types.map(value => ({ value, label: value })));
        setKingdoms(response.data.kingdoms.map(value => ({ value, label: value })));
        let evidence = response.data.evidence;
        evidence = evidence.filter(e => e != null);
        evidence = evidence.filter(e => e != "TRUE");
        setEvidenceTypes(evidence.map(value => ({ value, label: value })));
      }
      useEffect(() => {
    
        getIntitalCheck();
      }, []);

      const handleInputChange = async (e) => {
        console.log(e)
        if (isAutoFilled) {
          setFormData({
            uniprotId: "",
            genbankId: "",
            martsId: "",
            enzymeName: "",
            aminoacidSequence: "",
            species: "",
            kingdom: ""
          });
          setReactions([]);
          setIsAutoFilled(false); 
          return;
        }
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        
        const result = await checkDatabase(name, value);
        console.log(result);
        if (result) {
          isAutoFilling.current = true;
          setFormData({
            uniprotId: result.uniprotId,
            genbankId: result.genbankId,
            martsId: result.martsId,
            enzymeName: result.enzymeName,
            aminoacidSequence: result.aminoacidSequence,
            species: result.species,
            kingdom: result.kingdom
          });
          setIsAutoFilled(true);
          if (result.reactions){
          setReactions(result.reactions || []);
        } else {
          setReactions([]);
        }
          isAutoFilling.current = false;
        }
    };


    const addReaction = () => {
      setReactions([
        ...reactions,
        { type: "", class: "", substrates: [{smiles:"", name:"", marts:"", chebi:""}], product_mol: {smiles:"", name:"", marts:"", chebi:""}, publication: "", publicationdoi: "", isAutoFilled: false, is_new: true, mechanism_marts: null, mechanism_steps : [], final_mech_step: "", final_mech_evidence: "", mechanism_publication: "", mechanism_doi: ""},
      ]);
    };

    const removeReaction = () => {
      if (reactions.length === 0 || reactions[reactions.length - 1].is_new === false) {
        return;
      }
      setReactions(reactions.slice(0, -1));
    };
  
    const handleReactionChange = (index, updatedReaction) => {
      setReactions(reactions.map((r, i) => (i === index ? updatedReaction : r)));
    };
    
      // Placeholder function - you'll implement this
      async function checkDatabase(field, value) {
        console.log("Checking dtaabase {field, value}:", field, value);
        if (value == "") {
          return null
        }

        const req = {
          uniprotId: null,
          genbankId: null,
          martsId:  null,
          requestType: "enzyme"
        }
        if (field=="uniprotId"){
          if (!/^[OPQ][0-9][A-Z0-9]{3}[0-9]|[A-NR-Z][0-9]([A-Z][A-Z0-9]{2}[0-9]){1,2}$/.test(value)) {
            return null;
          }
          req.uniprotId = value
        }
        else if (field=="genbankId"){
          req.genbankId = value
        }
        else if (field=="martsId"){
          if (!/^marts_[ERM][0-9]{5}$/.test(value)) {
            return null;
          }
          req.martsId = value
        }
        else{
          return null
        }
        const response = await axios.post("/submitcheck", req);
        if (typeof response.data === 'object' && response.data !== null) {
          return {
              uniprotId: response.data.uniprot_id,
              genbankId: response.data.genbank_id,
              martsId: response.data.persistent_id,
              enzymeName: response.data.name,
              aminoacidSequence: response.data.aminoacid_sequence,
              species: response.data.species,
              kingdom: response.data.kingdom,
              reactions: groupSubstrates(response.data.reactions)
          }
      } else if (typeof response.data === 'string') {
          return null;
      }
        return null;
      }


       function checkIfSubmissionOk() {
        if (formEnzymeData.uniprotId == "" && formEnzymeData.genbankId == ""){
          return "Please enter a Uniprot ID or a Genbank ID";
        }
        if (formEnzymeData.enzymeName == ""){
          return "Please enter an enzyme name";
        }
        if (formEnzymeData.aminoacidSequence == ""){
          return "Please enter an amino acid sequence";
        }
        if (formEnzymeData.aminoacidSequence.length < 60){
          return "Amino acid sequence must be at least 60 characters long";
        }
        if (formEnzymeData.species == ""){
          return "Please enter a species";
        }
        if (formEnzymeData.kingdom == ""){
          return "Please enter a kingdom";
        }
        for (let i = 0; i < reactions.length; i++){
          if (reactions[i].is_new){
            if (reactions[i].type in ["hemi", "mono", "sesq", "di", "tri", "sester", "pt", "tetra"]){
              return "Please enter a correct type for reaction " + (i+1);
            }
            if (!(reactions[i].class in [1,2])){
              return "Please enter either 1 or 2 as class for reaction " + (i+1);
            }
            if (reactions[i].publication == "" || reactions[i].publicationdoi == ""){
              return "Please enter a publication for reaction " + (i+1);
            }
            if (reactions[i].substrates.length == 0){
              return "Please enter at least one substrate for reaction " + (i+1);
            }
            for (let j = 0; j < reactions[i].substrates.length; j++){
              if (reactions[i].substrates[j].smiles == ""){
                return "Please enter a SMILES string for substrate " + (j+1) + " of reaction " + (i+1);
              }
            }
            if (reactions[i].product_mol.smiles == ""){
              return "Please enter a SMILES string for the product of reaction " + (i+1);
            }
          }
        }
        return "Submission OK";
      }

      function transformDataForSubmission(formEnzymeData, reactions) {
        return reactions.filter(reaction => reaction.is_new).map(reaction => {
            const substrates = reaction.substrates.map(sub => ({
                name: sub.name,
                smiles: sub.smiles,
                chebi: sub.chebi,
                marts: sub.marts
            }));
            
            return {
                Enzyme_marts_ID: formEnzymeData.martsId,
                Uniprot_ID: formEnzymeData.uniprotId,
                Genbank_ID: formEnzymeData.genbankId,
                Enzyme_name: formEnzymeData.enzymeName,
                Aminoacid_sequence: formEnzymeData.aminoacidSequence,
                Species: formEnzymeData.species,
                Kingdom: formEnzymeData.kingdom,
                Type: reaction.type,
                Class: reaction.class,
                Substrate_name: substrates.map(sub => sub.name).join(';'),
                Substrate_smiles: substrates.map(sub => sub.smiles).join(';'),
                Substrate_chebi_ID: substrates.map(sub => sub.chebi).join(';'),
                Substrate_marts_ID: substrates.map(sub => sub.marts).join(';'),
                Product_name: reaction.product_mol.name,
                Product_smiles: reaction.product_mol.smiles,
                Product_chebi_ID: reaction.product_mol.chebi,
                Product_marts_ID: reaction.product_mol.marts,
                Reaction_has_mechanism: reaction.mechanism_marts ? true : false,
                Publication: reaction.publication,
                Publication_DOI: reaction.publicationdoi,
                Mechanism_marts_ID: reaction.mechanism_marts,
                User: "0"
            };
        });
    }

    function transformMechanismDataForSubmission(formEnzymeData, reactions) {
      function getEnzymeId(enzymeData) {
        return enzymeData.martsId || enzymeData.uniprotId || enzymeData.genbankId || "";
    }

    let steps = [];
    
    reactions.forEach(reaction => {
        if (reaction.mechanism && reaction.mechanism.length > 0) {
            let enzyme_id = getEnzymeId(formEnzymeData);
            let substrate = reaction.substrates[0];
            let product = reaction.product_mol;
            let prevMolecule = substrate;
            
            reaction.mechanism.forEach(step => {
                let nextMolecule = step.molecule;
                
                steps.push({
                    enzyme_id,
                    substrate_smiles: prevMolecule.smiles,
                    substrate_name: prevMolecule.name,
                    substrate_chebi_ID: prevMolecule.chebi,
                    substrate_marts_ID: prevMolecule.marts,
                    product_smiles: nextMolecule.smiles,
                    product_name: nextMolecule.name,
                    product_chebi_ID: nextMolecule.chebi,
                    product_marts_ID: nextMolecule.marts,
                    mechanism_type: step.mechanism,
                    mechanism_publication: reaction.mechanism_publication,
                    mechanism_publication_DOI: reaction.mechanism_doi,
                    mechanism_evidence: step.evidence
                });
                
                prevMolecule = nextMolecule;
            });
            
            steps.push({
                enzyme_id,
                substrate_smiles: prevMolecule.smiles,
                substrate_name: prevMolecule.name,
                substrate_chebi_ID: prevMolecule.chebi,
                substrate_marts_ID: prevMolecule.marts,
                product_smiles: product.smiles,
                product_name: product.name,
                product_chebi_ID: product.chebi,
                product_marts_ID: product.marts,
                mechanism_type: reaction.final_mech_step,
                mechanism_publication: reaction.mechanism_publication,
                mechanism_publication_DOI: reaction.mechanism_doi,
                mechanism_evidence: reaction.final_mech_evidence
            });
        }
    });
    
    return steps;
    }

      async function submitEntry() {
        const check = checkIfSubmissionOk();
        if (check == "Submission OK"){
          const submissions = transformDataForSubmission(formEnzymeData, reactions);
          const mechanismSubmissions = transformMechanismDataForSubmission(formEnzymeData, reactions);
          console.log(mechanismSubmissions);
          setSubmitMessage("Submission OK");
          for (let i = 0; i < submissions.length; i++){
            console.log(submissions[i]);
            await axios.post("/submitentry", submissions[i]);
          }
          for (let i = 0; i < mechanismSubmissions.length; i++){
            console.log(mechanismSubmissions[i]);
            await axios.post("/submitentry", mechanismSubmissions[i]);
          }
          navigate("/successsubmit")
        }
        else{
          setSubmitMessage(check);
        }
      }
    
      return (
        <div id="statsbody">
        <HomeNavbar />
        <div id="basicpage">
          <StatsBox title="Enzyme">
            <StatsBox title="Identifiers">
              <p>Enter a unique identifier for your enzyme. If the enzyme already exists the information will be filled in.</p>
          <SubmitInputField name="uniprotId" value={formEnzymeData.uniprotId} onChange={handleInputChange} label="Uniprot ID" tooltip="Uniprot ID is the prefered identifier for enzymes in marts." placeholder="Q41594" />
          <SubmitInputField name="genbankId" value={formEnzymeData.genbankId} onChange={handleInputChange} label="Genbank ID" tooltip="For enzymes that do not have Uniprot entry, genbank id can be used." placeholder="XP_055691875.1"/>
          <SubmitInputField name="martsId" value={formEnzymeData.martsId} onChange={handleInputChange} label="Marts ID" tooltip="Enzymes already in MARTS have Marts ID, fill in only if you want to update information about known enzyme." placeholder="marts_E00023" />
            </StatsBox>
            <StatsBox title="Detail fields">
              <p>Enter additional information about the enzyme.</p>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
          <SubmitInputField name="enzymeName" value={formEnzymeData.enzymeName} onChange={handleInputChange} label="Enzyme Name" tooltip="Enzyme name should be a very short description. Does not have to be unique." placeholder="Taxadiene synthase"/>
          <SubmitInputField name="aminoacidSequence" value={formEnzymeData.aminoacidSequence} onChange={handleInputChange} label="Amino Acid Sequence" placeholder="MGKIVEKSVLFEEVLVELKEFAALQKCDTEYLERMLRYHNENIFGGILYLD" tooltip="Fill in the full aminoacid sequence of the enzyme."/>         
          <SubmitCreatableSelect label={"Kingdom"} options={kingdoms} onChange={handleInputChange} placeholder="Animalia (Coral)" target="kingdom" value={formEnzymeData.kingdom}/>
            <SubmitInputField name="species" value={formEnzymeData.species} onChange={handleInputChange} label="Species" tooltip="The latin name of the species this enzyme comes from." placeholder="Arabidopsis thaliana"/>
            </div>
            </StatsBox>
          </StatsBox>

          <StatsBox title="Reactions">
            <p>Define reactions catalyzed by your enzyme.</p>
          {reactions.map((reaction, index) => (
        <ReactionForm
          key={index}
          reaction={reaction}
          onChange={(updatedReaction) => handleReactionChange(index, updatedReaction)}
          setMechanismBody={setMechanismBody}
          mechanismTypes={mechanismStepTypes}
          evidenceTypes={evidenceTypes}
        />
      ))}
      
        <div className="button-container">
          <div>
          <button
          class = "basic-button"
          onClick={addReaction}
          >
          Add Reaction
        </button>
        <button
          class = "basic-button"
          onClick={removeReaction}
          disabled={reactions.length === 0 || reactions[reactions.length - 1].is_new === false}
          >
          Remove Reaction
        </button>
        </div>
        {SubmitMessage}
        <button
          class = "basic-button"
          onClick={submitEntry}
          >
          <nobr> <FontAwesomeIcon icon={faCheck} /> Submit Entry</nobr>
        </button>
        </div>
          </StatsBox>
        </div>
        <Footer />
<MechanismWindow>
<MechanismTable
  heading={[
    "Intermediate substrate",
    "Intermediate product",
    "Mechanism type",
  ]}
  body={mechanismBody}
/>
</MechanismWindow>
    </div>




      );


}


export default SubmitPage;