import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function ErrorMessage({name, message}) {
  return (
    <div id="noresults">
      <h1>
        <FontAwesomeIcon icon={faExclamationCircle} color="red" />
      </h1>
      <h1>{name}</h1>
      <p>
        {message}
      </p>
    </div>
  );
}

export default ErrorMessage;
