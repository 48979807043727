import "./Footer.css";
import { Link } from "react-router-dom";
import MARTSDB from "../MARTS-DB.svg";

function Footer({}) {
  return (
    <div
      className="row justify-content-left"
      id="BackgroundUpper"
      style={{
        boxShadow:
          "0 5px 20px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="col-sm-12">
        <div id="Footer">
          <ul id="ulFooter">
            <li id="listValue">
              <Link id="FooterLink" to="/advancedsearch">
                Advanced search
              </Link>
            </li>
            <li id="listValue">
              <Link id="FooterLink" to="/submit">
                Submit entry
              </Link>
            </li>
            <li id="listValue">
              <Link id="FooterLink" to="/statistics">
                Statistics
              </Link>
            </li>
            <li id="listValue">
              <Link id="FooterLink" to="/aboutpage">
                Help
              </Link>
            </li>
          </ul>
        </div>
        <div className="row justify-content-left" id="BackgroundLower">
          <div className="col-md-6">
            <div id="FooterPic"></div>
          </div>
          <div className="col-md-6" align="right" id="FooterIOCB">
            This project has been funded by Tomáš Pluskal group: <br />{" "}
            <Link to="https://www.pluskal-lab.org" id="IOCBLink">
              Biochemistry of Plant Specialized Metabolites
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
