import KingdomIcon from "../ReactionTable/kingdomIcon.js";
import ResultInfoText from "../ResultsTable/ResultInfoText.js";
import translateTerpeneType from "../../api/typeTranslator.js";

function EnzymeBasicInfo({ enzymeData }) {

    return(
    <div className="enzyme-record-table">
                {/*<div className="enzyme-record-row">
                    <div className="enzyme-record-label">Enzyme:</div>
                    <div className="enzyme-record-divider"></div>
                    <div className="enzyme-record-value">{enzymeData.name}</div>
                </div>*/}
                {enzymeData.uniprot && (
                    <div className="enzyme-record-row">
                        <div className="enzyme-record-label">Uniprot:</div>
                        <div className="enzyme-record-divider"></div>
                        <div className="enzyme-record-value">
                        <ResultInfoText text={enzymeData.uniprot} row={3} fontSize="18px" buttonClass="plane-text-button-no-background" linkText="See in UniProt"
           link={`https://www.uniprot.org/uniprotkb/${enzymeData.uniprot}/entry`} />
                        </div>
                    </div>
                )}
                {enzymeData.genbank && (
                    <div className="enzyme-record-row">
                        <div className="enzyme-record-label">Genbank:</div>
                        <div className="enzyme-record-divider"></div>
                        <div className="enzyme-record-value">
                        <ResultInfoText text={enzymeData.genbank} row={4} fontSize="18px" buttonClass="plane-text-button-no-background" linkText="See in Genbank"
           link={`https://www.ncbi.nlm.nih.gov/protein/${enzymeData.genbank}`} />
                        </div>
                    </div>
                )}
                <div className="enzyme-record-row">
                    <div className="enzyme-record-label">Taxonomy:</div>
                    <div className="enzyme-record-divider"></div>
                    <div className="enzyme-record-value">{enzymeData.species + " · " + enzymeData.kingdom}<KingdomIcon table={[enzymeData]} row={0} isQuickSearch={true} interactiveSearch={() => {}} backgroundColor="rgb(255,255,255)" /></div>
                </div>
                <div className="enzyme-record-row">
                    <div className="enzyme-record-label">Amino acid sequence:</div>
                    <div className="enzyme-record-divider"></div>
                    <div className="enzyme-record-value">{enzymeData.aminoacid_sequence.length + " amino acids"}</div>
                </div>
                <div className="enzyme-record-row">
                    <div className="enzyme-record-label">Reaction classes:</div>
                    <div className="enzyme-record-divider"></div>
                    <div className="enzyme-record-value">{[...enzymeData.classes].join(",")}</div>
                </div>
                <div className="enzyme-record-row">
                    <div className="enzyme-record-label">Reaction types:</div>
                    <div className="enzyme-record-divider"></div>
                    <div className="enzyme-record-value">{[...enzymeData.types].map((type) => (translateTerpeneType(type, true))).join(",")}</div>
                </div>
            </div>)
}

export default EnzymeBasicInfo;