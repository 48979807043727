
import React from "react";

function StatsBox({ title, children }) {
  return (
    <div className="stats-box">
      <h2 className="stats-title">{title}</h2>
      <div className="stats-content">{children}</div>
    </div>
  );
}

export default StatsBox;