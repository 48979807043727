
import React from "react";
import Tooltip from "../Tooltip.js";

function SubmitInputField({ name, value, onChange, label, tooltip = null, disabled = false, placeholder = "" }) {
    const content = (
        <label className="input-field-submit">
          <span className="input-field-submit-label">{label}</span>
          <input type="text" name={name} value={value} onChange={onChange} disabled = {disabled} placeholder={placeholder}/>
        </label>
      );
    
      return tooltip ? <Tooltip content={tooltip}>{content}</Tooltip> : content;
  }

export default SubmitInputField;