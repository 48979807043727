import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function NavBarButton({ text, route, tip, icon=null, func=null }) {

const [mouseOver, setMouseOver] = useState(false);
const navigate = useNavigate();
function handleClick() {
  if (func) {
    func();
  } else {
    navigate(route);
  }
}


return(
<li
style={{
  position: "relative",
  paddingBottom: 11,
  paddingTop: 13,
}}
class="navbar-list"
>
<button
  onClick={()=>{handleClick();}}
  className="drop-down-navbar"
  style={{
    position: "relative",
    backgroundColor: "rgb(23, 55, 151,0.0)",
    border: "none",
    color: "white",
    pointerEvents: "auto",
  }}
  onMouseOver={() => setMouseOver(true)}
  onMouseLeave={() => setMouseOver(false)}
>
  <nobr> {(icon && <FontAwesomeIcon icon={icon} />)} {text}</nobr>
</button>
{mouseOver ? (
  <div class="tips-navbar" style={{ position: "absolute" }}>
   {tip}
  </div>
) : (
  <></>
)}
</li>)
}

export default NavBarButton;