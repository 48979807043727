import React from "react";
import ResultInfoText from "./ResultInfoText.js";
import { useNavigate } from "react-router-dom";

function MoleculeInfo({ molecule, interactiveSearch }) {
    const row = Math.floor(Math.random() * 100);
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/record/${molecule.marts}`);
    };

    return (
        <div style={{ textAlign: "center" }}>
        <ResultInfoText text={molecule.name} row={row} onClick={handleClick}/>
        <br />
        <ResultInfoText text={molecule.marts} row={row} linkText={`Find in ChEBI: ${molecule.chebi}`}
           link={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${molecule.chebi}`} onClick={handleClick}/>
        </div>
    )
}

export default MoleculeInfo;