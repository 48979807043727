import React, { Component } from "react";
import StatsBox from "../StatsPage/StatsBox.js";
import InfoTable from "../InfoTable.js";
import ResultInfoText from "../ResultsTable/ResultInfoText.js";
import KingdomIcon from "../ReactionTable/kingdomIcon.js";


import getDataByEnzymesFromSearchResults from "../../api/transformSearchResultsToEnzymes.js";
import { useParams } from "react-router-dom";
import EnzymeBasicInfo from "./EnzymeBasicInfo.js";
import AminoAcidDisplay from "./AminoAcidDisplay.js";
import ReactionResultBox from "../ResultsTable/ReactionResultBox.js";
import SmallLoading from "../smallLoading.js";




function EnzymeRecord({searchResult, setMechBody, navigateToRecord}) {

    if (!searchResult.result){
        return <div className="loading-div"><SmallLoading /></div>;
    }

    const enzymeData = getDataByEnzymesFromSearchResults(searchResult.result)[0];
    const history = searchResult.marts;
    return (
        <>
        <StatsBox title={
            <><ResultInfoText text={enzymeData.name} row={1} fontSize="25px" buttonClass="plane-text-button-no-background"/>  {" · "}
            <ResultInfoText text={enzymeData.marts} row={1} fontSize="20px" buttonClass="plane-text-button-no-background"/></>}>
            <EnzymeBasicInfo enzymeData={enzymeData} />
        </StatsBox>
        <StatsBox title="Reactions">
        {enzymeData.reactions.map((reaction, index) => (
            <ReactionResultBox table={enzymeData.reactions} row={index} interactiveSearch={() => {}} setMechBody={setMechBody} maxWidth="150px" imageScale="1" displayTextCol={true} navigateToRecord={navigateToRecord}/>
            ))}
        </StatsBox>
        <StatsBox title="Amino acid sequence">
            <AminoAcidDisplay sequence={enzymeData.aminoacid_sequence} enzymeInfo={enzymeData} />
        </StatsBox>
        <StatsBox title="Record history">
            <InfoTable data={[["Date"].concat(history.map(row => row.note_date.split("T")[0])), ["Record"].concat(history.map(row => row.note))]} />
        </StatsBox>
        </ >
    );

}

export default EnzymeRecord;