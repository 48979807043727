import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import StatsBox from "../Components/StatsPage/StatsBox.js";
import ResultInfoText from "../Components/ResultsTable/ResultInfoText.js";

function SuccessSubmitPage() {
    const navigate = useNavigate();

    return (
        <div id="statsbody">
            <HomeNavbar />
            <div id="basicpage">
                <StatsBox title={<ResultInfoText text="Submission successful" row={1} fontSize="30px" buttonClass="plane-text-button-no-background"/>}>
                    <p>Your submission was entered into the database. It will be reviewed and made public with the next database release. Thank you!</p>
                    <p>You can make a new submission.</p>
                    <button
                    class = "basic-button"
                    onClick={() => {navigate("/submit")}}
                    >
                    New submission
                    </button>
                </StatsBox>
            </div>
            <Footer />
        </div>
    );
}

export default SuccessSubmitPage;