import React from "react";
import { useState } from "react";
import "./ResultTable.css";
import EnzymeMartsHeader from "./EnzymeMartsHeader.js";
import ResultInfoText from "./ResultInfoText.js";
import KingdomIcon from "../ReactionTable/kingdomIcon.js";
import ReactionResultBox from "./ReactionResultBox.js";
import { useNavigate } from "react-router-dom";

function EnzymeResultBox({ table, row, interactiveSearch, setMechBody, navigateToRecord }) {
    const [reactionsToShow, setReactionsToShow] = useState(table[row].reactions.slice(0, 1));
    const [remainingReactionsCount, setRemainingReactionsCount] = useState(table[row].reactions.length - reactionsToShow.length);
    const navigate = useNavigate()

    function showMoreReactions() {
      setReactionsToShow(table[row].reactions);
      setRemainingReactionsCount(0);
    }

    function clickSearchFunction(searchText) {
      if (interactiveSearch){
      interactiveSearch(searchText);
      }
    }

    return (
      <>
      <div className="result-box">
      <div><ResultInfoText text={table[row].name} row={row} fontSize="20px" onClick={() => navigate(`/record/${table[row].marts}`)}/> {" · "}
        <ResultInfoText text={table[row].marts} row={row} fontSize="20px" onClick={() => navigate(`/record/${table[row].marts}`)}/>
        {table[row].uniprot && (
          <>
          {" · "}
          <ResultInfoText text={table[row].uniprot} row={row} fontSize="17px" linkText="See in UniProt"
           link={`https://www.uniprot.org/uniprotkb/${table[row].uniprot}/entry`} onClick={interactiveSearch}/>
          </>
        )}
        {table[row].genbank && (
          <>
          {" · "}
          <ResultInfoText text={table[row].genbank} row={row} fontSize="17px" linkText="See in GenBank"
          link={`https://www.ncbi.nlm.nih.gov/protein/${table[row].genbank}`} onClick={interactiveSearch} />
          </>
        )}
      </div> 
      <ResultInfoText text={table[row].name} row={row} onClick={interactiveSearch} />
       {" · "}
      <ResultInfoText text={table[row].species} onClick={interactiveSearch} row={row} />
      <KingdomIcon table={table} row={row} isQuickSearch={true} interactiveSearch={clickSearchFunction}/>
      {" · "}
      <ResultInfoText text={table[row].aminoacid_sequence.length+ " amino acids"} row={row} />
      {" · "}
      <ResultInfoText text={"Reaction classes: " + [...table[row].classes].join(",")} row={row} />
      {" · "}
      <ResultInfoText text={"Reaction types: " + [...table[row].types].join(",")} row={row} />
      </div>
      <div className="results-reactions-div">
      {reactionsToShow.map((reaction, index) => (
      <ReactionResultBox table={table[row].reactions} row={index} interactiveSearch={interactiveSearch} setMechBody={setMechBody} navigateToRecord={navigateToRecord} />
      ))}
      {remainingReactionsCount > 0 && (
      <div className="remaining-reactions-box">
        <button className="plane-text-button" onClick={showMoreReactions}>
        {remainingReactionsCount} more reaction{remainingReactionsCount > 1 ? 's' : ''}...
        </button>
      </div>
      )}
      </div>
      </>
    );
  }

  export default EnzymeResultBox;