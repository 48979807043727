

function dataToCsv(data){
let res = "Enzyme_marts_ID,Uniprot_ID,Genbank_ID,Enzyme_name,Aminoacid_sequence,Species,Kingdom,Type,Class,Substrate_name,Substrate_smiles,Substrate_chebi_ID,Substrate_marts_ID,Product_name,Product_smiles,Product_chebi_ID,Product_marts_ID,Reaction_has_mechanism,Mechanism_marts_ID,Publication\n"
data.forEach((element) => {
    let has_mechanism = (element.mechanism_id != null);
    let mechanism_marts_id = has_mechanism ? element.mechanism_pers_id : "no_mechanism";
    let substrate_name = element.substrate_name
    let substrate_smiles = element.substrate_smiles
    let substrate_chebi = element.substrate_chebi
    let substrate_pers_id = element.substrate_pers_id
    if (element.cosubstrates.length != 0){
        element.cosubstrates.forEach((cosubs) => {
            substrate_name = substrate_name + ";" + cosubs.name;
            substrate_smiles = substrate_smiles + ";" + cosubs.smiles;
            substrate_chebi = substrate_chebi + ";" + cosubs.chebi;
            substrate_pers_id = substrate_pers_id + ";" + cosubs.persistent_id;
        })
    }
    let new_line = `"${element.enzyme_pers_id}","${element.uniprot_id}","${element.genbank_id}","${element.name}","${element.aminoacid_sequence}","${element.species}","${element.kingdom}","${element.type}","${element.class}","${substrate_name}","${substrate_smiles}","${substrate_chebi}","${substrate_pers_id}","${element.product_name}","${element.product_smiles}","${element.product_chebi}","${element.product_pers_id}",${has_mechanism},${mechanism_marts_id},"${element.reaction_link}"\n`
    res = res + new_line
}); 
return res
} 

export default dataToCsv