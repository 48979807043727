function BasicRecordTable({rows, records}) {
  return (
    <div className="enzyme-record-table">
        {rows.map((row, index) => (
            <div key={index} className="enzyme-record-row">
            <div className="enzyme-record-label">{row}</div>
            <div className="enzyme-record-divider"></div>
            <div className="enzyme-record-value">{records[index]}</div>
            </div>
        ))}

    </div>
  );
}

export default BasicRecordTable;