import { useState } from "react";


const Tooltip = ({ children, content }) => {
    const [visible, setVisible] = useState(false);
  
    return (
      <div
        className="tooltip-container"
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {children}
        {visible && <div className="tooltip">{content}</div>}
      </div>
    );
  };

export default Tooltip;