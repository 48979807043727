import UniprotLink from "../Components/ReactionTable/uniprotLink.js";
import GenbankLink from "../Components/ReactionTable/genbankLink.js";
import SubstrateRow from "../Components/ReactionTable/substrateRow.js";
import ProductRow from "../Components/ReactionTable/productRow.js";
import KingdomIcon from "../Components/ReactionTable/kingdomIcon.js";
import CopySequence from "../Components/ReactionTable/copySequence.js";
import TypeClass from "../Components/ReactionTable/typeClass.js";
import CosubstrateRow from "../Components/ReactionTable/cosubstrateRow.js";
import InteractiveImage from "../Components/ReactionTable/interactiveImage.js";
import renderMechanism from "./renderMechanism.js";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import ReactionLink from "../Components/ReactionTable/reactionLink.js";
import MechanismEvidence from "../Components/ReactionTable/mechanismEvidence.js";
import ExternalLink from "../Components/ReactionTable/externalLink.js";

function getDataByEnzymesFromSearchResults(
  table){
    console.log(table)
 let enzymes = [];
if (table.length == 0) {
        return enzymes;
    }

 let currentEnzyme = table[0].enzyme_marts;
 let currentEnzymeReactions = [];

 

 for (let row = 0; row < table?.length; row++) {
   if (table[row].enzyme_marts != currentEnzyme){
    enzymes.push({
        reactions: currentEnzymeReactions,
        marts: table[row-1].enzyme_marts,
        uniprot: table[row-1].uniprot_id,
        genbank: table[row-1].genbank_id,
        name: table[row-1].name,
        kingdom: table[row-1].kingdom,
        species: table[row-1].species,
        aminoacid_sequence: table[row-1].aminoacid_sequence,
        types: new Set(currentEnzymeReactions.map((reaction) => {
            return reaction.type
        })),
        classes: new Set(currentEnzymeReactions.map((reaction) => {
            return reaction.class
        }))
    })
     currentEnzyme = table[row].enzyme_marts;
     currentEnzymeReactions = [];
   }    
   let substrates = [];
    substrates.push({
      name: table[row].substrate_name,
      id: table[row].substrate,
      smiles: table[row].substrate_smiles,
      chebi: table[row].substrate_chebi,
      marts: table[row].substrate_marts})
    for (let i = 0; i < table[row].cosubstrates.length; i++) {
        substrates.push({
            name: table[row].cosubstrates[i].name,
            id: table[row].cosubstrates[i].substrate,
            smiles: table[row].cosubstrates[i].smiles,
            chebi: table[row].cosubstrates[i].chebi,
            marts: table[row].cosubstrates[i].marts
        })
    }
     currentEnzymeReactions.push({         
            substrates: substrates,
            product: {
            name: table[row].product_name,
            id: table[row].product,
            smiles: table[row].product_smiles,
            chebi: table[row].product_chebi,
            marts: table[row].product_marts,
            },
            mechanism_marts: table[row].mechanism_marts,
            type: table[row].type,
            class: table[row].class,
            publication: table[row].reaction_link,
            doi: table[row].reaction_doi,
            mechanism_id: table[row].mechanism_id,
            mechanism_link: table[row].mechanism_link,
            mechanism_doi: table[row].mechanism_doi,
            mechanism_evidence: table[row].mechanism_evidence,
            enzyme_marts: table[row].enzyme_marts,
     });
 }
 enzymes.push({
    reactions: currentEnzymeReactions,
    marts: table[table.length - 1].enzyme_marts,
    uniprot: table[table.length - 1].uniprot_id,
    genbank: table[table.length - 1].genbank_id,
    name: table[table.length - 1].name,
    kingdom: table[table.length - 1].kingdom,
    species: table[table.length - 1].species,
    aminoacid_sequence: table[table.length - 1].aminoacid_sequence,
    types: new Set(currentEnzymeReactions.map((reaction) => {
        return reaction.type
    })),
    classes: new Set(currentEnzymeReactions.map((reaction) => {
        return reaction.class
    }))
})
console.log(enzymes)
return enzymes;

}

export default getDataByEnzymesFromSearchResults