import React, { Component } from "react";
import Footer from "../Components/Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import StatsBox from "../Components/StatsPage/StatsBox.js";
import ResultInfoText from "../Components/ResultsTable/ResultInfoText.js";
import axios from "../api/axios.js";
import InfoTable from "../Components/InfoTable.js";
import ExternalLink from "../Components/ReactionTable/externalLink.js";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

function HelpPage() {
  return (
    <div id="statsbody">
      <HomeNavbar />
      <div id="basicpage">
        <ResultInfoText text={"About MARTS"} fontSize="25px" buttonClass="plane-text-button-no-background" />
        <StatsBox title={"What is MARTS?"}>
          <p>
            MARTS-DB stands for mechanisms and reactions of terpene synthases database. 
            It is a manually curated database of terpenes, terpene synthases, and their reaction mechanisms.
          </p>
          <StatsBox title={"Why and where?"}>
            <p>
              MARTS was founded at the Institute of Organic Chemistry and Biochemistry in Prague as a 
              source for machine learning projects targeting enzymatic function prediction and even enzyme design. 
              The original manually annotated dataset was transformed into a public database.
            </p>
          </StatsBox>
        </StatsBox>
        <StatsBox title={"How to use MARTS?"}>
          <p>
            MARTS can be browsed either in quick search mode or in advanced search mode. Quick search allows 
            selecting a single search term, while advanced search allows for more complex queries. The database can 
            also be downloaded as a CSV file.
          </p>
          <StatsBox title={"Data identifiers"}>
            The main records in the MARTS database have unique MARTS identifiers, or MARTS IDs. 
            MARTS IDs are assigned to enzymes, molecules (terpenes, isoprenoid substrates, and intermediate cations), and reaction mechanisms.
            MARTS IDs have the structure marts_[EMR] and a five-digit number. E is for enzyme IDs, M for molecule IDs, and R for mechanism IDs.
          </StatsBox>
          <StatsBox title={"How to contribute?"}>
            <p>
              Users can submit new entries into the database using the submission form. Only registered users can submit
              new entries. All submitted entries first undergo a revision process and are released with the next database update.
            </p>
          </StatsBox>
          <StatsBox title={"Download data structure"}>
            The main download is structured into two main tables: the terpene synthases with their reactions table and the mechanisms table.
            The reactions table contains the following information. Every line is one reaction.
            <InfoTable data={[["Column", "Enzyme_marts_ID", "Uniprot_ID", "Genbank_ID", "Enzyme_name", "Aminoacid_sequence",
              "Species", "Kingdom", "Type", "Class", "Substrate_name", "Substrate_smiles", "Substrate_chebi_ID",
              "Substrate_marts_ID", "Product_name", "Product_smiles", "Product_chebi_ID", "Product_marts_ID",
              "Reaction_has_mechanism", "Mechanism_marts_ID", "Publication"],
              ["Content", "Marts ID of the enzymes.", "Uniprot ID for enzymes that have a Uniprot record.",
                "Record of the protein transcript in Genbank.", "A descriptive name of the protein.", "The entire amino acid sequence of the protein.",
                "The species the enzyme was isolated from.", "A broader taxonomic group of this species. Kingdoms have been split into groups of interest. Those groups are always written in brackets like animalia(corals).",
                "The type of the reaction. In terpene synthase reactions, type means the type of the terpene product, which is the number of carbons. The type is written as an abbreviation, e.g., mono for monoterpenes or di for diterpenes. Sesquiterpenes are abbreviated as sesq. Since isoprenyl diphosphate synthases and squalene synthases are also included, their type is given as pt, abbreviation of prenyl transferases.",
                "The class of the reaction. Terpene synthase reactions are divided into class I and class II reactions based on the initiation of the reaction cascade. Class I reactions are initiated by abstraction of a pyrophosphate, while class II by protonation of an alkyl or epoxide bond.",
                "The commonly used name of the substrate.", "The SMILES representation of the substrate. All diphosphates are written as oxoanions with charge on the diphosphate group.", "The ChEBI ID of the substrate.", "The MARTS ID of the substrate.",
                "The commonly used name of the product.", "The SMILES representation of the product. All diphosphates are written as oxoanions with charge on the diphosphate group.", "The ChEBI ID of the product.", "The MARTS ID of the product.",
                "A boolean value indicating if the reaction has a mechanism in MARTS.", "The MARTS ID of the mechanism.", "The publication the reaction was taken from."]]} />
            The next table describes the known mechanisms of terpene synthases. The mechanisms are divided into steps, which describe roughly the change of a single bond in the reactive carbocation. Steps are described by the types of reaction that are taking place. Every row is one step.
            <InfoTable data={[["Column", "Enzyme_uniprot_ids", "Enzyme_genbank_ids", "Enzyme_marts_ids", "Substrate_smiles", "Substrate_name", "Substrate_marts_id", "Product_smiles", "Product_name", "Product_marts_id", "Reaction_type", "Evidence", "Publication", "Mechanism_marts_id"],
              ["Content", "The Uniprot IDs of enzymes that utilize this mechanism in their reaction, separated by commas.", "The Genbank IDs of the enzymes that utilize this mechanism in their reaction, separated by commas.",
                "The MARTS IDs of the enzymes that utilize this mechanism in their reaction, separated by commas.", "The SMILES representation of the molecule that enters this reaction step. All diphosphates are written as oxoanions with charge on the diphosphate group.",
                "The commonly used name of the molecule that enters this step.", "The MARTS ID of the molecule that enters this step.",
                "The SMILES representation of the product of this step. All diphosphates are written as oxoanions with charge on the diphosphate group.",
                "The commonly used name of the product of this step.", "The MARTS ID of the product of this step.",
                "The type of this reaction step. Types are described in their section of the about page.", "The method of investigation for this mechanism. The methods are usually similarity, trivial, experiment, and calculation.",
                "The publication the mechanism was taken from.", "The MARTS ID of the mechanism."]]} />
          </StatsBox>
        </StatsBox>
        <StatsBox title={"Terpenes and terpene synthases"}>
          <p>
            Terpenes are secondary metabolites with diverse biological roles, arguably the most notable of which is serving as 
            the precursors to terpenoids - the largest class of natural products, comprising more than 100,000 known compounds. 
            The function of terpenoids in nature is as varied as their structure. Most serve as specialized secondary metabolites
            produced as responses to environmental factors by plants, fungi, and bacteria. In the animal kingdom, they are represented 
            by steroids and insect pheromones. This functional richness translates to their wide usage across different industries,
            from cosmetic and food to pharmaceutical. This remarkable chemical diversity originates from a relatively small pool
            of isoprenoid diphosphate precursors whose transformation to terpenes is guided by terpene synthases (TPS).
            The reactions catalyzed by these enzymes are among the most complex in nature, described as inducing changes 
            in bonding and hybridization in more than half of the substrate’s carbon atoms.
          </p>
          <h4>Substrates and terpene types</h4>
          <p>
            <img src={`/mol_1.png`} alt="Farnesyl diphosphate" class="wrapped-image"></img>
            The substrates of terpene synthases are sometimes called isoprenoid diphosphates. They are linear molecules
            composed of five-carbon isoprene units. Based on the number of isoprene units in the substrate, terpenes are divided into types. 
            In nature, the double bonds in the substrates commonly take the trans configuration, but there are examples of 
            <ul>
              <li>Hemiterpene (C5) is a term sometimes used for isoprene.</li>
              <li>Monoterpenes (C10) are mostly created from Geranyl diphosphate (GPP). There are known monoterpenes created by alternative linking of isoprene units, like chrysanthemyl.</li>
              <li>Sesquiterpenes (C15) are mostly created from Farnesyl diphosphate (FPP, displayed on the right). Some sesquiTPS are known to utilize FPP with cis bond orientation. There are also rare cases of sesquiTPS that utilize special substrates with sixteen carbons.</li>
              <li>Diterpenes (C20) are mostly created from Geranylgeranyl diphosphate (GGPP). There is a large group of diTPS that catalyze class II reactions that create another group of possible substrates for class I diTPS, those are called copalyl-like substrates.</li>
              <li>Sesterterpenes (C25) are mostly created from Geranylfarnesyl diphosphate (GFPP).</li>
              <li>Triterpenes (C30) are mostly created from squalene and oxidosqualene. There are rare cases of triTPS that utilize hexaprenyl diphosphate.</li>
              <li>Sesquarterpenes (C35) are a little-known group synthesized from heptaprenyl diphosphate, which is transformed into tetraprenyl-β-curcumene.</li>
              <li>Tetraterpenes (C40) are synthesized from phytoene and lycopene.</li>
            </ul>
          </p>
          <h4>Terpene synthases</h4>
          Terpene synthases are a family of lyases (<ExternalLink
            idLink={`linkEC`}
            idDiv={`divEC`}
            icon={faExternalLink}
            externalLink={"https://enzyme.expasy.org/EC/4.2.3.-"}>
            {"In EXPASY"}
          </ExternalLink> EC number 4.2.3 - Carbon-Oxygen lyases acting on phosphates)
          that catalyze the conversion of isoprenoid diphosphates into terpenes. The reactions they catalyze are described as the most complex in nature,
          with more than half of the substrate’s carbon atoms undergoing changes in bonding and hybridization. 
          Their reactions always start with the formation of a carbocation, either by abstraction of a pyrophosphate or
          by protonation of an alkyl or epoxide bond. This distinction divides reactions between class I and II respectively. 
          While most terpene synthases catalyze only class I reactions, it is not uncommon to have a hybrid enzyme. 
          Following ionization, the active site of the TPS guides the intrinsic reactivity of the carbocation.
          <p>
            Isoprenoid precursors are formed by enzymes very similar to terpene synthases. They are called either isoprenoid diphosphate
            synthases or, in a broader sense, prenyl transferases. Such enzymes connect together the isoprene units. In our database, they are marked by the abbreviation pt for prenyl transferase.
          </p>
          <h5>Structural motifs</h5>
          Terpene synthases are described as guides to the intrinsic reactivity of the carbocations they create. Therefore, their active sites do not possess
          many typical structural motifs. The commonly known motifs of terpene synthase active sites have the main role of coordinating metal ions that
          stabilize the pyrophosphate group and therefore allow for its abstraction. The DDxxD motif is known to have this function in class I enzymes.
          Generally, when it is present in the sequence twice, it is an indication of the enzyme being an isoprenyl diphosphate synthase.
          Another motif of class I TPSs is the NSE/DTE motif described as (N,D)D(L,I,V)X(S,T)XXXE (or in a regular expression [DN]D[LIV].[ST]...E). 
          Class II TPSs are known to possess the DxDD motif, which is needed for the protonation of an alkene double bond. These are only the most common motifs.
          Not all TPSs have these motifs, especially in this canonical form. The enzyme page displays all places in the sequence where a sequence of this motif is found.
          <StatsBox title={"Reaction mechanisms of terpene synthases"}>
            <p>
              Reaction mechanisms of terpene synthases always start with the formation of a reactive carbocation, which is then guided through a series of 
              changes towards the product. Reaction mechanisms in MARTS are divided into steps, which mostly describe changes in a single bond. This unfortunately does not 
              accurately describe the exact transition states as sometimes the reactions proceed in a concerted manner. There are the following types of steps in MARTS:
              <InfoTable data={[["Step type", "Dephosphorylation", "Protonation-class II", "Cyclization", "Hydride shift", "Methyl shift", "WM rearrangement", "Proton transfer", "Bond cleavage",
                "Oxygen cyclization", "Phosphorylation", "Protonation", "Deprotonation", "Hydroxylation", "Cyclopropanation deprotonation", "Fragmentation", "Cycloaddition"],
                ["Description", "Describes the abstraction of the diphosphate group, the initiation of a class I TPS reaction.", "Describes the initiation of a class II reaction either by the protonation of an alkene bond or of an epoxide bond. While for alkenes the DxDD motif is required, the higher basicity of epoxides does not require the canonical motif to proceed.",
                  "Describes a nucleophilic attack of a double bond on the positive charge of the carbocation. This leads to the formation of a ring.", "Describes the transfer of a single hydride ion between a carbon and the carbocation.",
                  "Describes the transfer of a methyl group between a carbon and the carbocation. This predominantly happens between neighboring carbons.",
                  "Describes Wagner-Meerwein rearrangement, which generally is a 1,2 migration of a ring carbon atom in a polycyclic system. In MARTS, it describes the change of a skeleton of a ring.",
                  "Describes a transfer of a proton between a proton and a double bond, resulting therefore in a change of the double bond position.",
                  "Describes a cleavage of a C-C bond that results in a formation of a new double bond. A rare step in terpene biosynthesis, described especially for some triterpenes.",
                  "Describes a reaction that is akin to a nucleophilic attack of an OH group on the carbocation resulting in the abstraction of the alcohol hydrogen and formation of a heterocycle.",
                  "During the reaction cascade of some terpenes, the orientation of the double bond is changed, known generally as isomerization. In MARTS, this is described as dephosphorylation followed by phosphorylation and another dephosphorylation. During this, the double bond is reoriented. This is required for all cyclic monoterpenes and some sesqui and diterpenes.",
                  "Some reaction cascades proceed through a formation of a stable intermediate, which is then protonated (even in class I reactions).",
                  "Describes the most common final step of the reaction cascade, an abstraction of a proton that leads to the formation of a double bond.",
                  "Describes a nucleophilic attack of water on the carbocation, leading to the formation of an alcohol, thus ending the reaction cascade.",
                  "Describes a rare end to the reaction cascade when a cyclization coupled with deprotonation leads to the formation of a cyclopropyl ring. This is also the way in which saturated terpenes are formed.",
                  (<span>Recently, terpenes created from unusual precursors with uncanonical numbers of carbons (e.g., the 16-carbon sesquiterpene <a href="/record/marts_M01111">sodorifen</a>) have been discovered. During their reaction mechanisms, the carbocation is often fragmented into two parts.</span>),
                  "In the rare cases where the carbocation is fragmented, it is often mended through a cycloaddition reaction."]]} />
            </p>
          </StatsBox>
          <StatsBox title={"Read more"}>
            For more information refer to sources for our database recrods or the following reviews:
            <ul>
              <li><ExternalLink
                idLink={`linkTPS`}
                idDiv={`divTPS`}
                icon={faExternalLink}
                externalLink={"https://pubs.acs.org/doi/10.1021/acs.chemrev.7b00287"}>
                {"In ACS"} </ExternalLink> - Structural and Chemical Biology of Terpenoid Cyclases (David W. Christianson, 2017)</li>
              <li><ExternalLink
                idLink={`linkTPS2`}
                idDiv={`divTPS2`}
                icon={faExternalLink}
                externalLink={"https://onlinelibrary.wiley.com/doi/10.1111/j.1365-313X.2011.04520.x#b48"}>
                {"In wiley"} </ExternalLink> - The family of terpene synthases in plants: a mid-size family of genes for specialized metabolism that is highly diversified throughout the kingdom (Chen, Feng, et al., 2011)</li>
                <li><ExternalLink
                idLink={`linkTPS3`}
                idDiv={`divTPS3`}
                icon={faExternalLink}
                externalLink={"https://pubs.acs.org/doi/full/10.1021/acscatal.3c03047"}>
                {"In ACS"} </ExternalLink> - Decoding Catalysis by Terpene Synthases (Whitehead, Joshua N., et al., 2023)</li>
                <li><ExternalLink
                idLink={`linkTPS4`}
                idDiv={`divTPS4`}
                icon={faExternalLink}
                externalLink={"https://www.frontiersin.org/journals/plant-science/articles/10.3389/fpls.2019.01166/full"}>
                {"In frontiers"} </ExternalLink> - Terpene Synthases as Metabolic Gatekeepers in the Evolution of Plant Terpenoid Chemical Diversity (Karunanithi, Prema S., and Philipp Zerbe., 2019)</li>

            </ul>
          </StatsBox>
        </StatsBox>
        <StatsBox title="About us">
          MARTS-DB was created at the <ExternalLink
            idLink={`linkIOCB`}
            idDiv={`divIOCB`}
            icon={faExternalLink}
            externalLink={"https://www.uochb.cz/en"}>
            {"IOCB"}
          </ExternalLink>
          Institute of Organic Chemistry and Biochemistry in Prague in the <ExternalLink
            idLink={`linkPluskal`}
            idDiv={`divPluskal`}
            icon={faExternalLink}
            externalLink={"https://pluskal.group.uochb.cz/en"}>
            {"Laboratory of Tomáš Pluskal"}
          </ExternalLink> laboratory of the Biochemistry of Plant Specialized Metabolites. 
          Originally devised as a dataset for machine learning projects like (<ExternalLink
            idLink={`linkRaman`}
            idDiv={`divRaman`}
            icon={faExternalLink}
            externalLink={"https://www.biorxiv.org/content/10.1101/2024.01.29.577750v3"}>
            {"At bioRxiv"}
          </ExternalLink> Samusevich, Raman, et al., 2024).
        </StatsBox>
      </div>
      <Footer />
    </div>
  );
}

export default HelpPage;