import React from "react";
import "./InfoTable.css";


function InfoTable({ data }) {
    if (!data || data.length === 0) {
        return <p>No data available</p>;
    }


    const headers = data.map(column => column[0]);

    const rowCount = Math.max(...data.map(col => col.length - 1));
    const transposedData = Array.from({ length: rowCount }, (_, rowIndex) =>
        data.map(column => column[rowIndex + 1] || "") 
    );

    return (
        <table className="info_table">
            <thead className="info_thead">
                <tr>
                    {headers.map((header, colIndex) => (
                        <th key={colIndex} className="info_th">{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {transposedData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, colIndex) => (
                            <td key={colIndex} className="info_td">{cell}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default InfoTable;