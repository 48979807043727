
import React from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function MechanismSelectionButton({ marts_id, onClick, renderFunction}) {
    return(
        <>
        <button
        className="basic-button"
        onClick={() => renderFunction(marts_id)}
      >
        <FontAwesomeIcon icon={faEye} />
  </button>   
    <button
          className="basic-button"
          onClick={() => onClick(marts_id)}
        >
          {marts_id}
    </button>
    </>
    );
  }

export default MechanismSelectionButton;