import { useState , useEffect} from "react";
import StatsBox from "../StatsPage/StatsBox.js";
import SubmitInputField from "./submitInputField.js";
import axios from "../../api/axios.js";

export default function MoleculeForm({ molecule, title_text, disabled, onChange, moleculeId_start=null }) {
  const [isAutoFilled, setIsAutoFilled] = useState(false);

  const [moleculeId, setMoleculeId] = useState(moleculeId_start);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;


    onChange({ ...molecule, [name]: value });

    if (isAutoFilled && !disabled) {
      console.log("Auto filled, resetting");
      setMoleculeId(null);
      setIsAutoFilled(false);
      return;
    }

    // Check database for molecule info
    const result = await checkMoleculeDatabase(name, value);
    if (result) {
      setIsAutoFilled(true);
      setMoleculeId(result.molecule_id);
      onChange(result);
    }
  };

  async function checkMoleculeDatabase(field, value) {
    console.log("Checking database {field, value}:", field, value);
            if (value == "") {
              return null
            }   
            const req = {
              smiles: null,
              martsId:  null,
              chebiId: null,
              requestType: "molecule"
            }
            if (field=="smiles"){
              req.smiles = value
            }
            else if (field=="name"){
              return null;
            }
            else if (field=="marts"){
              if (!/^marts_[ERM][0-9]{5}$/.test(value)) {
                return null;
              }
              req.martsId = value
            }
            else if (field=="chebi"){
              req.chebiId = value
            }
            else{
              return null
            }
            const response = await axios.post("/submitcheck", req);
            if (typeof response.data === 'object' && response.data !== null) {
              return {
                  smiles: response.data.smiles,
                  name: response.data.name,
                  marts: response.data.persistent_id,
                  chebi: response.data.chebi,
                  molecule_id: response.data.molecule_id
              }
          } else if (typeof response.data === 'string') {
              return null;
          }
    return null;
  }

  return (
    <div>

        <StatsBox title={title_text}>
        <SubmitInputField name="smiles" value={molecule.smiles || ""} onChange={handleInputChange} label="SMILES" disabled={disabled} tooltip="Enter the SMILES of your molecule." placeholder="CC(C)=CCC/C(C)=C/COP(=O)([O-])OP(=O)([O-])[O-]"/>
        <SubmitInputField name="name" value={molecule.name || ""} onChange={handleInputChange} label="Name" disabled={disabled} tooltip="Enter a the most commonly used name for this molecule. If left empty unnamed intermediate or molecule is used instead." placeholder="(2E)-GPP" />
        <SubmitInputField name="chebi" value={molecule.chebi || ""} onChange={handleInputChange} label="ChEBI ID" disabled={disabled} tooltip="If this molecule existst in ChEBI database the ID can be linked. It is recommended to deposit any new molecule to ChEBI as well. " placeholder="58057"/>
        <SubmitInputField name="marts" value={molecule.marts || ""} onChange={handleInputChange} label="Marts ID" disabled={disabled} tooltip="Molecules already in MARTS have Marts ID, fill in only if you want to use a molecule that is already inside the database." placeholder="marts_M00312"/>
        <img key={moleculeId} src={`/mol_${moleculeId}.png`} alt={`${molecule.name}`} style={{ width: "100px", height: "auto" }} onError={(e) => e.target.style.display = 'none' } />
      </StatsBox>
    </div>
  );
}