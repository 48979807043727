import React, { Component } from "react";
import StatsBox from "../StatsPage/StatsBox.js";
import InfoTable from "../InfoTable.js";
import ResultInfoText from "../ResultsTable/ResultInfoText.js";
import KingdomIcon from "../ReactionTable/kingdomIcon.js";


import getDataByEnzymesFromSearchResults from "../../api/transformSearchResultsToEnzymes.js";
import { useParams } from "react-router-dom";
import BasicRecordTable from "./BasicRecordTable.js";
import AminoAcidDisplay from "./AminoAcidDisplay.js";
import ReactionResultBox from "../ResultsTable/ReactionResultBox.js";
import translateTerpeneType from "../../api/typeTranslator.js";
import renderSearchResults from "../../api/renderSearchResults.js";
import { useState } from "react";
import SmallLoading from "../smallLoading.js";
import { useNavigate } from "react-router-dom";

function MoleculeRecord({searchResult, setMechBody, navigateToRecord}) {
    

    if (!searchResult.reactions){
        return <div className="loading-div"><SmallLoading /></div>;
    }

    const reactionsData = getDataByEnzymesFromSearchResults(searchResult.reactions);
    const reactionsBody = renderSearchResults({table: reactionsData, setMechBody, isQuickSearch: true, interactiveSearch: null, dataProcessed: true, navigateToRecord: navigateToRecord});
    const history = searchResult.marts;
    const molecule = searchResult.molecule;
    const kingdoms = new Set(reactionsData.map(row => row.kingdom));
    const species = new Set(reactionsData.map(row => row.species));
    
    const speciesCountPerKingdom = Array.from(kingdoms).reduce((acc, kingdom) => {
        acc[kingdom] = new Set(
            reactionsData
                .filter(row => row.kingdom === kingdom)
                .map(row => row.species)
        ).size;
        return acc;
    }, {});
    const typeCounts = {};
    const classCounts = {};

    let foundReaction = false;

    reactionsData.forEach(row => {
        row.reactions.forEach(reaction => {
            if (reaction.product.marts === molecule.marts) {
                foundReaction = true;
                typeCounts[reaction.type] = (typeCounts[reaction.type] || 0) + 1;
                classCounts[reaction.class] = (classCounts[reaction.class] || 0) + 1;
            }
        });
    });

    if (!foundReaction) {
        reactionsData.forEach(row => {
            row.reactions.forEach(reaction => {
                typeCounts[reaction.type] = (typeCounts[reaction.type] || 0) + 1;
                classCounts[reaction.class] = (classCounts[reaction.class] || 0) + 1;
            });
        });
    }

    const mostCommonType = Object.keys(typeCounts).reduce((a, b) => typeCounts[a] > typeCounts[b] ? a : b, null);
    const mostCommonClass = Object.keys(classCounts).reduce((a, b) => classCounts[a] > classCounts[b] ? a : b, null);

    return (
        <>
        <StatsBox title={
            <><ResultInfoText text={molecule.name} row={1} fontSize="25px" buttonClass="plane-text-button-no-background"/>  {" · "}
            <ResultInfoText text={molecule.marts} row={1} fontSize="20px" buttonClass="plane-text-button-no-background"/></>}>
            <img src={`/mol_${molecule.molecule_id}.png`} alt={molecule.name} class="wrapped-image"></img>
            <BasicRecordTable rows={["SMILES", "Formula","Molecular mass", "Type", "Chebi", "Kingdoms", "Enzymes", "InChI", "InChIKey"]} records={[molecule.smiles, molecule.formula,molecule.molmass, translateTerpeneType(mostCommonType),
             (molecule.chebi ? <ResultInfoText text={molecule.chebi} row={2} linkText={`Find in ChEBI: ${molecule.chebi}`}
           link={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${molecule.chebi}`} buttonClass="plane-text-button-no-background"/> : "Missing in chebi"),
           [...kingdoms].sort().map((kingdom, index) => (<KingdomIcon table={kingdoms} row={index-25} isQuickSearch={true} interactiveSearch={() => {}} backgroundColor="rgb(255,255,255)" kingdomVal={kingdom} speciesVal={` in ${speciesCountPerKingdom[kingdom]} species`}/>))
           , reactionsData.length, molecule.inchi, molecule.inchikey ]} />
        </StatsBox>
        <StatsBox title = "Reactions and Enzymes">
        {reactionsBody}
        </StatsBox>
        <StatsBox title="Record history">
            <InfoTable data={[["Date"].concat(history.map(row => row.note_date.split("T")[0])), ["Record"].concat(history.map(row => row.note))]} />
        </StatsBox>
        </ >
    );

}

export default MoleculeRecord;