import React from "react";
import AminoAcidSequence from "./AminoAcidSequence.js";
import { faDownload, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatsBox from "../StatsPage/StatsBox.js";

const AminoAcidDisplay = ({ sequence, highlights = [], blockSize = 10, enzymeInfo }) => {
  
  const [ddxxd, setDdxxd] = React.useState(getRegexIntervals(sequence, /DD..D/g));
  const [dxdd, setDxdd] = React.useState(getRegexIntervals(sequence, /D.DD/g));
  const [nsedte, setNsedte] = React.useState(getRegexIntervals(sequence, /[DN]D[LIV].[ST]...E/g));

  function getRegexIntervals(str, regex) {
    if (!(regex instanceof RegExp)) {
        throw new Error("Second argument must be a RegExp");
    }
    
    let matches = [];
    let match;
    let globalRegex = new RegExp(regex, regex.flags.includes("g") ? regex.flags : regex.flags + "g");
    
    while ((match = globalRegex.exec(str)) !== null) {
        matches.push([match.index, match.index + match[0].length]);
    }
    
    return matches;
}
    

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sequence);
  };

  const downloadFASTA = () => {
    const fastaContent = `>${enzymeInfo.marts}|${enzymeInfo.name}|${enzymeInfo.species}\n${sequence}`;
    const blob = new Blob([fastaContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${enzymeInfo.marts}.fasta`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
       <div style={{marginBottom: "10px"}}>
        <button onClick={copyToClipboard} className="basic-button"><FontAwesomeIcon icon={faCopy} /> Copy</button>
        <button onClick={downloadFASTA} className="basic-button"><FontAwesomeIcon icon={faDownload} /> Download FASTA</button>
      </div>
      <div className="sequence-info">
      <AminoAcidSequence sequence={sequence} highlights={ddxxd.concat(dxdd, nsedte)} blockSize={blockSize} />
      </div>
      {ddxxd.concat(dxdd, nsedte).length > 0 && (
      <StatsBox title={"Potential structural motifs"}>
        {ddxxd.length > 0 && <p>DDXXD: {ddxxd.map(([start, end]) => `${start + 1}-${end}`).join(", ")}</p>}
        {dxdd.length > 0 && <p>DXDD: {dxdd.map(([start, end]) => `${start + 1}-${end}`).join(", ")}</p>}
        {nsedte.length > 0 && <p>NSE/DTE: {nsedte.map(([start, end]) => `${start + 1}-${end}`).join(", ")}</p>}
      </StatsBox>)}
    </>
  );
};

export default AminoAcidDisplay;