import React from "react";

const AminoAcidSequence = ({ sequence, highlights = [], blockSize = 10 }) => {
  // Split sequence into blocks of defined size
  const blocks = sequence.match(new RegExp(`.{1,${blockSize}}`, "g")) || [];

  const isHighlighted = (index) => {
    return highlights.some(([start, end]) => index > start && index <= end);
  };

  return (
    <div className="amino-sequence">
      {blocks.map((block, index) => {
        const position = index * blockSize + 1;
        return (
          <div key={index} className="sequence-row">
            <div className="sequence-numbers">
              {Array.from({ length: block.length }).map((_, i) =>
                (position + i) % blockSize === 1 || i === 0 ? (
                  <span key={i}>{position + i}</span>
                ) : (
                  <span key={i}>&nbsp;&nbsp;</span>
                )
              )}
            </div>
            <div className="sequence-text">{block.split("").map((char, i) => {
                const globalIndex = position + i;
                return (
                  <span
                    key={i}
                    className="amino-acid"
                    style={{ color: isHighlighted(globalIndex) ? "rgb(23, 55, 151)" : "black" }}
                  >
                    {char}
                  </span>
                );
              })}</div>
          </div>
        );
      })}
    </div>
  );
};

export default AminoAcidSequence;