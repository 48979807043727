import React, { Component } from "react";
import "./Statistics.css";
import axios from "../api/axios.js";
import Footer from "../Components/Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import InfoTable from "../Components/InfoTable.js";
import { useState } from "react";
import { useEffect } from "react";
import SmallLoading from "../Components/smallLoading.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatsBox from "../Components/StatsPage/StatsBox.js";
import { groupKingdoms, groupTypesAndKingdoms, calculateAminoAcidFrequency } from "../functions/statisticsDataProcessing.js";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';
import { BarChart, Bar,  Rectangle, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import StackedAreaChart from "../Components/StatsPage/StackedKDEPlot.js";
import { constants } from "buffer";




function Statistics(){
  const [stats, setStats] = useState(null)

  const colors = ['#006e00','#b24502','#ebac23','#ff9287','#5954d6','#b80058','#00c6f8', "#00FF00"]
  const kingdomColors = {
    Plantae: '#006e00',
    Fungi: '#b24502',
    Bacteria: '#ebac23',
    Animalia: '#ff9287',
    Amoebozoa: '#5954d6',
    Archae: '#b80058',
    Viruses: '#00c6f8',
    Cyanobacteria: "#00FF00"
  };
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  function processStats(stats){
    const kingdomOccurence = groupKingdoms(stats.kingdomsCounts);
    const typesAndKingdoms = groupTypesAndKingdoms(stats.terpeneTypesByKingdomCount);
    const aaFreq = calculateAminoAcidFrequency(stats.sequences)
    stats.kingdomOccurence = kingdomOccurence;
    stats.groupedTypesKingdoms = typesAndKingdoms[0];
    stats.kingdoms = typesAndKingdoms[1]; 
    stats.aaFreq = aaFreq;
    return stats;
  }

   async function loadStats() {
      const stats = await axios.post("/statspage");
      const updatedStats = processStats(stats.data);
      setStats(updatedStats);
      console.log(stats);
      console.log(updatedStats);
    }

    useEffect(() => {
        loadStats();
      }, []);

      if (!stats) {
        return(
          <div id="statsbody">
          <HomeNavbar />
          <div id="statspage">
              <h1>Marts-DB statistics</h1>
        <StatsBox title={"Loading statistics..."}>
          <SmallLoading />
          </StatsBox>
          </div>
          <Footer /> 
          </div>)  
      }
    return (
        <div id="statsbody">
        <HomeNavbar />
      <div id="basicpage">
        <h1>Marts-DB statistics</h1>
        <StatsBox title={"Basics"}>
          <p>Marts-DB is a database of terpene synthases, their reactions and reaction mechanisms. </p>
          <InfoTable data = {[["Type", "Enzymes", "Reactions catalyzed", "Products", "Substrates", "Unique mechanisms", "Kingdoms", "Species"],
          ["Number of entries", stats.enzymeCount[0].enzymeCount, stats.reactionCount[0].reactionCount, stats.productCount[0].productCount, stats.substrateCount[0].substrateCount, stats.mechanismCount[0].mechanismCount, stats.kingdomOccurence.length, stats.speciesCount.length]]}/>

         </StatsBox>

        <StatsBox title={"Enzymes"}> 
        <StatsBox title={"Taxonomic origin"}>
          <div className="stats-layout">
            <div className="stats-table">
            <InfoTable data={[["Kingdom"].concat(stats.kingdomOccurence.map(k => k.kingdom)), ["Number of enzymes"].concat(stats.kingdomOccurence.map(k => k.occurrence_count))]} /> 
            </div>
            <div className="stats-chart">
        <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
        <Pie
            dataKey="occurrence_count"
            nameKey="kingdom"
            data={stats.kingdomOccurence}
            cx="50%"
            cy="50%"
            outerRadius="100%"
            labelLine={false}
            label={renderCustomizedLabel}
          >
          {stats.kingdomOccurence.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
        </Pie>
        <Legend />
        </PieChart>
        </ResponsiveContainer>
            </div>  
          </div>
          <h2></h2>
          <h2 className="stats-title">Represented species: {stats.speciesCount.length}</h2>
          <div className="stats-layout">
             <div className="stats-table">
            <InfoTable data={[["Most represented species"].concat(stats.speciesCount.slice(0,10).map(s => s.species)), ["Number of enzymes"].concat(stats.speciesCount.slice(0,10).map(s => s.occurrence_count))]} />
            </div>
            <div className="stats-table">
            <InfoTable data={[["Most represented species"].concat(stats.speciesCount.slice(10,20).map(s => s.species)), ["Number of enzymes"].concat(stats.speciesCount.slice(10,20).map(s => s.occurrence_count))]} />
            </div>
          </div>
        </StatsBox>
        <StatsBox title = {"Distribution by terpene types"}>
          <p>Based on the length of the substrate, terpenes are divided into types. Hemi terpenes contain five carbons, mono terpenes ten, sesquiterpenes fifteen and so on.</p>
          <ResponsiveContainer width="100%" height={400}>
          <BarChart
          data={stats.groupedTypesKingdoms}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis />
          <Tooltip />
          <Legend />
          {stats.kingdoms.map((group, index) => (
            <Bar key={group} dataKey={group} stackId="a" fill={colors[index]} />
          ))}
        </BarChart></ResponsiveContainer>
        </StatsBox>
        <StatsBox title={"Aminoacid frequency"}>
          <div className="stats-layout">
            <div className="stats-table">
            <InfoTable data={[["Aminoacid"].concat(stats.aaFreq.map(i => i.aa)), ["Frequency"].concat(stats.aaFreq.map(i => i.freq))]} />
            </div>
            <div className="stats-chart">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={stats.aaFreq}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="aa" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="freq" fill="green" activeBar={<Rectangle fill="green" stroke="blue" />} />
                </BarChart>
              </ResponsiveContainer>
              </div>
          </div>
        </StatsBox>
        </StatsBox>

        <StatsBox title={"Terpenes"}>
          <StatsBox title={"Distribution by terpene types"}>
            <div className="stats-layout">
            <div className="stats-table">
            <InfoTable data={[["Type"].concat(stats.terpenesByType.map(t => t.type)), ["Number of unique products"].concat(stats.terpenesByType.map(t => t.terpene_count))]} />
            </div>
            <div className="stats-chart">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={stats.terpenesByType}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="type" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="terpene_count" fill="green" activeBar={<Rectangle fill="green" stroke="blue" />} />
                </BarChart>
              </ResponsiveContainer>
              </div>
            </div>
          </StatsBox>
          <StatsBox title={"Substrates by number of reactions"}>
          <div className="stats-layout">
             <div className="stats-table">
            <InfoTable data={[["Substrate"].concat(stats.substratesOccurence.slice(0,Math.ceil(stats.substratesOccurence.length/2)).map(s => s.name)), ["Number of reactions"].concat(stats.substratesOccurence.slice(0,Math.ceil(stats.substratesOccurence.length/2)).map(s => s.substrate_count))]} />
            </div>
            <div className="stats-table">
            <InfoTable data={[["Substrate"].concat(stats.substratesOccurence.slice(Math.ceil(stats.substratesOccurence.length/2),stats.substratesOccurence.length).map(s => s.name)), ["Number of reactions"].concat(stats.substratesOccurence.slice(Math.ceil(stats.substratesOccurence.length/2),stats.substratesOccurence.length).map(s => s.substrate_count))]} />
            </div>
          </div>
          </StatsBox>
        </StatsBox>
        <StatsBox title={"Mechanisms"}>
          <p>Mechanisms are divided into steps, every step has its type.</p>
          <StatsBox title={"Distribution of step types for unique mechanism steps"}>
            <div className="stats-layout">
            <div className="stats-table">
            <InfoTable data={[["Stepy type"].concat(stats.mechanismStepsCount.map(t => t.mechanism_type)), ["Number of occurrences"].concat(stats.mechanismStepsCount.map(t => t.mechanismStepsCount))]} />
            </div>
            <div className="stats-chart">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={stats.mechanismStepsCount}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="mechanism_type" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="mechanismStepsCount" fill="green" activeBar={<Rectangle fill="green" stroke="blue" />} />
                </BarChart>
              </ResponsiveContainer>
              </div>
            </div>
          </StatsBox>
        </StatsBox>
      </div>
        <Footer />
        </div>      
        );



}

export default Statistics;