import axios from "./axios.js";
import dataToCsv from "../functions/searchResultToCSV.js";
import mechanismDataToCsv from "../functions/mechanismDataToCSV.js";
import { Buffer } from "buffer";
import { RiGitRepositoryCommitsFill } from "react-icons/ri";
import { persistentIdToCsv, historicNoteToCSV } from "../functions/MiscDataToCSV.js";


async function downloadDatabase()
{
  try {
    let response = "";   
    response = await axios.post("/download", {});
    const data = response?.data;
    return [dataToCsv(data[0]), mechanismDataToCsv(data[1]), persistentIdToCsv(data[2]), historicNoteToCSV(data[3])];
}catch (err) {
    throw err
  } 
}


export default downloadDatabase;