import React from "react";
import ResultInfoText from "./ResultInfoText.js";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InteractiveImage from "../ReactionTable/interactiveImage.js";
import MoleculeInfo from "./MoleculeInfo.js";

function ReactionDisplay({ table, row, interactiveSearch, isQuickSearch, interactiveAdvancedSearch, showSubstratePopups, maxWidth="70px", imageScale="3.0", displayTextCol=false }) {
  return (
    <>
        {table[row].substrates.map((substrate, index) => (
                <>
                {displayTextCol ? (
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>                      
                        <InteractiveImage src={`/mol_${substrate.id}.png`}
                            alt={substrate.smiles}
                            className={"responsive"}
                            interactiveSearch={interactiveSearch}
                            showPopups={showSubstratePopups}
                            interactiveAdvancedSearch={() => {}}
                            isQuickSearch={true}
                            parameter={"reactSmiles"}
                            maxWidth={maxWidth}
                            imageScale={imageScale}/>
                          <MoleculeInfo molecule={substrate} interactiveSearch={interactiveSearch}/>
                    </div>
                ) : (
                  <>
                    <MoleculeInfo molecule={substrate} interactiveSearch={interactiveSearch}/>
                    <InteractiveImage src={`/mol_${substrate.id}.png`}
                        alt={substrate.smiles}
                        className={"responsive"}
                        interactiveSearch={interactiveSearch}
                        showPopups={showSubstratePopups}
                        interactiveAdvancedSearch={() => {}}
                        isQuickSearch={true}
                        parameter={"reactSmiles"}
                        maxWidth={maxWidth}
                        imageScale={imageScale}/>
                  </>
                )}
                {index < table[row].substrates.length - 1 && <FontAwesomeIcon icon={faPlus} />}
                </>
        ))}
        <FontAwesomeIcon icon={faArrowRight} />

        {displayTextCol ? ( <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <InteractiveImage src={`/mol_${table[row].product.id}.png`}
                    alt={table[row].product.smiles}
                    className={"responsive"}
                    interactiveSearch={interactiveSearch}
                    showPopups={showSubstratePopups}
                    interactiveAdvancedSearch={() => {}}
                    isQuickSearch={true}
                    parameter={"reactSmiles"}
                    maxWidth={maxWidth}
                    imageScale={imageScale}/>
        <MoleculeInfo molecule={table[row].product} interactiveSearch={interactiveSearch} /> </div> ) : (<>
        <InteractiveImage src={`/mol_${table[row].product.id}.png`}
                    alt={table[row].product.smiles}
                    className={"responsive"}
                    interactiveSearch={interactiveSearch}
                    showPopups={showSubstratePopups}
                    interactiveAdvancedSearch={() => {}}
                    isQuickSearch={true}
                    parameter={"reactSmiles"}
                    maxWidth={maxWidth}
                    imageScale={imageScale}/>
        <MoleculeInfo molecule={table[row].product} interactiveSearch={interactiveSearch} /> </>)}
    </>    
  );
}

export default ReactionDisplay;