import React, { Component } from "react";
import "./RecordPage.css";
import axios from "../api/axios.js";
import Footer from "../Components/Footer.js";
import HomeNavbar from "../HomeNavbar.js";
import { useState } from "react";
import { useEffect } from "react";
import SmallLoading from "../Components/smallLoading.js";
import StatsBox from "../Components/StatsPage/StatsBox.js";
import EnzymeRecord from "../Components/RecordPage/EnzymeRecord.js";


import ErrorMessage from "../Components/RecordPage/ErrorMessage.js";
import { useParams } from "react-router-dom";
import MechanismTable from "../mechanismTable.js";
import MechanismWindow from "../Components/MechanismTable/mechanismWindow.js";
import MoleculeRecord from "../Components/RecordPage/MoleculeRecord.js";
import { useNavigate } from "react-router-dom";



function RecordPage() {

    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [mechBody, setMechBody] = useState([]);
    const navigate = useNavigate()

    const navigateToRecord = (recordId) => {
        navigate(`/record/${recordId}`);

    }



    async function loadData() {
        console.log("Loading data for ID:", id);
        if (!id) {
            setError({name: "No ID provided", message: "No ID was provided in the URL."});
            setLoading(false);
          }
        if (!/^marts_[ERM][0-9]{5}$/.test(id)) {
            setError({name: "Invalid ID", message: "The provided ID is not a valid Marts ID."});
            setLoading(false);
            return
          }
        const res = await axios.post("/recorddetails", { marts_id: id });
        if (res.data === "No record found") {
            setError({name: "No record found", message: "No record was found with the provided Marts ID."});
            setLoading(false);
            return
        }
        setError(null);
        setData(res.data);
        setLoading(false);
    }


    useEffect(() => {
        setLoading(true);
        loadData()
      }, [id]);

    const getRecordComponent = () => {
        if (/^marts_[E][0-9]{5}$/.test(id)) {
            return <EnzymeRecord key={id} searchResult={data} setMechBody={setMechBody} navigateToRecord={navigateToRecord}/>;
        } else if (/^marts_[M][0-9]{5}$/.test(id)) {
            return <MoleculeRecord key={id} searchResult={data} setMechBody={setMechBody} navigateToRecord={navigateToRecord}/>;
        }
        return null;
    };

    return (
        <div id="statsbody">
            <HomeNavbar />
            <div id="basicpage">
            {loading ? (
                <StatsBox title={"Loading data..."}>
                    <SmallLoading />
                </StatsBox>
            ) : error ? (
                <ErrorMessage name={error.name} message={error.message} />
            ) : (
                getRecordComponent()
            )}

            </div>
            <Footer />
            <MechanismWindow>
        <MechanismTable
          heading={[
            "Intermediate substrate",
            "Intermediate product",
            "Mechanism type",
          ]}
          body={mechBody}
        />
      </MechanismWindow>
        </div>
    );



}

export default RecordPage;