import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import MoleculeNameButton from "../ReactionTable/moleculeNameButton.js";
import SmilesChebiRow from "../ReactionTable/smilesChebiRow.js";
import ExternalLink from "../ReactionTable/externalLink.js";
import CopySmilesButton from "../ReactionTable/copySmilesButton.js";
import ResultInfoText from "../ResultsTable/ResultInfoText.js";

function SubstrateRowMech({
  table,
  row,
  navigateToRecord,
}) {

  function copySmiles(id) {
    let smiles = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    smiles = smiles.trim();
    elem.value = smiles;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  return (
    <>
      {table[row].substrate_smiles ? (
        <>
          <MoleculeNameButton
            id={"substrateNameQuickSearch"}
            disabled={true}
          >
            {table[row].substrate_name === "unnamed_intermediate" ? "" : table[row].substrate_name}
          </MoleculeNameButton>

          <SmilesChebiRow>
            <CopySmilesButton table={table} row={row} copySmiles={copySmiles} substrateOrProduct={"substrate"} isMechanism={true}/>
            <p><ResultInfoText text={table[row].substrate_marts} row={row} fontSize="16px" onClick={() => navigateToRecord(table[row].substrate_marts)}/></p>
            <li
              style={{ marginRight: 5, position: "relative" }}
              className="chebiLinkMech"
            >
              {table[row].substrate_chebi ? (
                <>
                  <ExternalLink
                    externalLink={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${table[row].substrate_chebi}`}
                    icon={faExternalLink}
                    idDiv={`goToChebiMech${row}`}
                  >
                    Find in ChEBI: {table[row].substrate_chebi}
                  </ExternalLink>
                </>
              ) : (
                <></>
              )}
            </li>
          </SmilesChebiRow>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SubstrateRowMech;
