

function persistentIdToCsv(data){
    let res = "Marts_id,Entity,Uniprot_ID,Genbank_ID,SMILES,Mechanism_ID\n"
    data.forEach((element) => {
        let new_line = `"${element.persistent_id}","${element.source_table}","${element.Uniprot_ID}","${element.Genbank_ID}","${element.SMILES}","${element.mechanism_ID}"\n`
        res = res + new_line
    }); 
    return res
    } 

function historicNoteToCSV(data){
    let res = "Marts_id,Note,Date\n"
    data.forEach((element) => {
        let new_line = `"${element.persistent_id}","${element.note}","${element.note_date}"\n`
        res = res + new_line
    });
    return res
}
    
    export  { persistentIdToCsv, historicNoteToCSV }