import React, { useState } from "react";
import MoleculeForm from "./MoleculeForm.js";
import StatsBox from "../StatsPage/StatsBox.js";
import Creatable,  { useCreatable } from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import SubmitInputField from "./submitInputField.js";

const MechanismForm = ({ substrate, product, onChange, mechanismTypes, evidenceTypes, final_step_change }) => {
  const [mechanismSteps, setMechanismSteps] = useState([]);

  const addStep = () => {
    setMechanismSteps([
      ...mechanismSteps,
      { molecule: {}, mechanism: "", evidence: "" },
    ]);
    onChange([...mechanismSteps, { molecule: {}, mechanism: "", evidence: "" }]);
  };

  const removeLastStep = ( ) => {
    if (mechanismSteps.length === 0) {
      return;
    }
    const updatedSteps = [...mechanismSteps];
    updatedSteps.pop();
    setMechanismSteps(updatedSteps);
    onChange(updatedSteps);
  };

  const updateStep = (index, field, value) => {
    console.log("updateStep", index, field, value);
    const updatedSteps = [...mechanismSteps];
    updatedSteps[index] = { ...updatedSteps[index], [field]: value };
    setMechanismSteps(updatedSteps);
    onChange(updatedSteps);
  };


  const customStyles = {
    container: (base) => ({
      ...base,
      
    })
  };

  return (
    <StatsBox title="Mechanism">
      
      <MoleculeForm molecule={substrate} onChange={() => {}} title_text={"Substrate"} disabled={true} moleculeId_start={substrate.molecule_id} />

      {mechanismSteps.map((step, index) => (
        <div key={index} className="mechanism-step">
          <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
          <div style={{ flex: 1 }}>         
          <CreatableSelect id="Somethin" isClearable options={mechanismTypes} onChange={(e) => updateStep(index, "mechanism", e ? e.value : "")} placeholder={"Mechanism step type..."} />
          </div>
          <div style={{ flex: 1 }}>
          <CreatableSelect id="SomethinEvidence" isClearable options={evidenceTypes} onChange={(e) => final_step_change("evidence", e ? e.value : "")} placeholder={"Evidence..."} />
          </div>
          </div>
          <MoleculeForm title_text={"Intermediate"}
            molecule={step.molecule}
            onChange={(updatedMolecule) => updateStep(index, "molecule", updatedMolecule)}
          />
        </div>
      ))}
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ flex: 1, marginBottom: "10px" }}>         
          <CreatableSelect id="Somethin" isClearable options={mechanismTypes} onChange={(e) => final_step_change("final_mech_step", e ? e.value : "")} placeholder={"Mechanism step type..."} />
          </div>
          <div style={{ flex: 1 }}>
          <CreatableSelect id="SomethinEvidence" isClearable options={evidenceTypes} onChange={(e) => final_step_change("final_mech_evidence", e ? e.value : "")} placeholder={"Evidence..."} />
          
          {/*<SubmitInputField name="evidence"  onChange={(e) => final_step_change("final_mech_evidence", e.target.value)} label="Evidence"/>*/}
          </div>
        </div>
      <MoleculeForm molecule={product} onChange={() => {}} title_text={"Product"} disabled={true} moleculeId_start={product.molecule_id}   />
      <SubmitInputField name="publication"  onChange={(e) => final_step_change("mechanism_publication", e.target.value)} label="Publication link"/>
      <SubmitInputField name="publication"  onChange={(e) => final_step_change("mechanism_doi", e.target.value)} label="Publication DOI"/>
        <div>
      <button class="basic-button" onClick={addStep}>Add Step</button>
      <button class="basic-button" onClick={removeLastStep} disabled={mechanismSteps.length===0}>Remove Step</button>
      </div>
    </StatsBox>
  );
};

export default MechanismForm;