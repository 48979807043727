import React from "react";
import ResultInfoText from "./ResultInfoText.js";
import { faArrowRight, faBook, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExternalLink from "../ReactionTable/externalLink.js";
import MechanismEvidence from "../ReactionTable/mechanismEvidence.js";
import renderMechanism from "../../api/renderMechanism.js";
import ReactionDisplay from "./ReactionDisplay.js";




function ReactionResultBox({ table, row, interactiveSearch, setMechBody, maxWidth="70px", imageScale="3.0", displayTextCol=false, navigateToRecord }) {

    function drawSelectedMechanism(marts_id) {
      renderMechanism({
        mechanism_marts_id: marts_id,
        interactiveSearch: interactiveSearch,
        setMechBody: setMechBody,
        navigateToRecord: navigateToRecord
      });
    }


    return (
      <div className="reaction-box">
        {/* Left compartment for reaction details */}
        <div className="reaction-info">
          <ResultInfoText text={"Type: " + table[row].type} row={row} />
          <ResultInfoText text={"Class " + table[row].class} row={row} />
        </div>
  
        {/* Right compartment for reaction display */}
        <div className="reaction-display">
          <ReactionDisplay table={table} row={row} interactiveSearch={interactiveSearch} maxWidth={maxWidth} imageScale={imageScale} displayTextCol={displayTextCol} />
        </div>
        <div className="reaction-source">
        {(table[row].doi || table[row].publication) && ( <ExternalLink
                  idDiv={`goToReactionPublication${row + table[row].enzyme_marts}`}
                  externalLink={table[row].doi ? `https://doi.org/${table[row].doi}` : table[row].publication}
                  icon={faBook}
                >
                  See publication
                </ExternalLink> )}
        {table[row].mechanism_id && (
            <nobr >
            <MechanismEvidence table={table} row={row} />
            <button
              className="plane-text-button"
              style={{ padding: 0, marginRight: 5 }}
              onClick={() => drawSelectedMechanism(table[row].mechanism_marts)}><FontAwesomeIcon icon={faEye} color="rgb(0,0,0,0.8)" /></button>
            <ExternalLink
                  idDiv={`goToMechanismPublication${row + table[row].enzyme_marts}`}
                  externalLink={table[row].mechanism_doi ? `https://doi.org/${table[row].mechanism_doi}` : table[row].mechanism_link}
                  icon={faBook}
                >
                  See publication
                </ExternalLink></nobr>)}
        </div>
      </div>
    );
  };



export default ReactionResultBox;