import {
  faSeedling,
  faBacterium,
  faOtter,
  faVirus,
  faMale,
  faBug,
  faDisease,
} from "@fortawesome/free-solid-svg-icons";
import marineSpongeImg from "../icons/marine_sponge.svg";
import archaeaImg from "../icons/archaea.png";
import cyanobacteriaImg from "../icons/cyanobacteria.png";
import IconButton from "./iconButton.js";
import Icon from "./icon.js";

function KingdomIcon({
  suggestion,
  table,
  row,
  interactiveSearch,
  isQuickSearch,
  interactiveAdvancedSearch,
  showEnzymePopups,
  backgroundColor="rgb(245,245,245)",
  kingdomVal="",
  speciesVal="",
}) {
  const DISABLED_CONDITION = suggestion == undefined ? false : true;

  function handleClickIcon(key, value, input) {
    return isQuickSearch
      ? interactiveSearch(kingdom)
      : interactiveAdvancedSearch(showEnzymePopups, key, value, input);
  }

  const kingdomArray = {
    plantae: ["Plantae", "PlantaeInput", faSeedling, "rgb(0,191,0)"],
    bacteria: ["Bacteria", "BacteriaInput", faBacterium, "rgb(177,200,100)"],
    animaliaMammalHuman: ["Animalia (Mammal, Human)","AnimaliaMammalHumanInput",faMale,"rgb(0,0,0)",],
    animaliaCoral: ["Animalia (Coral)","AnimaliaCoralInput","GiCoral","rgb(193, 37, 117)",],
    animaliaMammal: ["Animalia (Mammal)","AnimaliaMammalInput",faOtter,"rgba(142, 74, 1, 0.764)",],
    animaliaBird: ["Animalia (Bird)","AnimaliaBirdInput","LuBird","rgb(0,0,0)",],
    archaea: ["Archaea", "ArchaeaInput", archaeaImg, "rgb(245,245,245)"],
    amoebozoa: ["Amoebozoa", "AmoebozoaInput", faDisease, "rgb(104, 255, 182)"],
    plantaeRedAlgae: ["Plantae (Red algae)","PlantaeRedAlgaeInput","GiAlgae","red",],
    cyanobacteria: ["Cyanobacteria","CyanobacteriaInput",cyanobacteriaImg,"rgb(245,245,245)",],
    viruses: ["Viruses", "VirusesInput", faVirus, "rgb(111, 205, 255)"],
    fungi: ["Fungi", "FungiInput", "GiSuperMushroom", "red"],
    animaliaInsect: ["Animalia (Insect)","AnimaliaInsectInput",faBug,"rgb(90, 7, 97)",],
    animaliaMarineSponge: ["Animalia (Marine Sponge)","AnimaliaMarineSpongeInput",marineSpongeImg,"rgb(245,245,245)",],
  };

  const kingdom = kingdomVal ? kingdomVal : table[row].kingdom;
  const species = speciesVal ? speciesVal : table[row].species;

  return (
    <>
      {Object.entries(kingdomArray).map(([key,value]) => {
        if (value[0] === kingdom) {
          return (
            <>
              <IconButton
                kingdom={kingdom}
                species={species}
                disabledCondition={DISABLED_CONDITION}
                row={row}
                backgroundColor={backgroundColor}
                handleOnClick={() =>
                  handleClickIcon(key, kingdom, value[1])
                }
              >
                <Icon alt={value[1]} icon={value[2]} color={value[3]} />
              </IconButton>
            </>
          );
        }
      })}
    </>
  );
}

export default KingdomIcon;
