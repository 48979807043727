import axios from "./axios.js";
import SubstrateRowMech from "../Components/MechanismTable/substrateRowMech.js";
import ProductRowMech from "../Components/MechanismTable/productRowMech.js";
import InteractiveImage from "../Components/ReactionTable/interactiveImage.js";

async function findMechanism({
  table,
  row,
  isQuickSearch,
  interactiveAdvancedSearch,
  showPopups,
  mechanismUnclicked,
  mechanism_marts_id=null,
  navigateToRecord,
}) {

  function clickImage(showPopups,parameter,alt,isRadioInput) {
    if (!interactiveAdvancedSearch){
      return;
    }
    document.getElementById("overlap").style.display = "none";
        document.getElementById("mechDiv").style.display = "none";
        const elements = document.querySelectorAll("#body *");
        elements.forEach((element) => {
          element.style.filter = "unset";
        });
    interactiveAdvancedSearch(showPopups,parameter,alt,isRadioInput)
  }

  let mechanismBody = [];

  try {
    const response = await axios.post("/mymechanism", {
      ...(mechanism_marts_id != null
      ? { marts_id: mechanism_marts_id }
      : { RE_pair: table[row].reaction_enzyme_pair_id }),
    });

    const mechanismTable = response.data.mechanism;

    for (let row = 0; row < mechanismTable.length; row++) {
      mechanismBody.push([
        mechanismTable[row].substrate_image ? (
          <>
            <button
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              disabled={isQuickSearch ? true : mechanismUnclicked ? true : false}
            >
              <InteractiveImage
                src={`/mol_${mechanismTable[row].substrate}.png`}
                alt={mechanismTable[row].substrate_smiles}
                className={"responsive"}
                showPopups={showPopups}
                interactiveAdvancedSearch={clickImage}
                isQuickSearch={isQuickSearch}
                parameter={"intermediateSmiles"}
              />
            </button>
            <br />
            <SubstrateRowMech
              table={mechanismTable}
              row={row}
              isQuickSearch={isQuickSearch}
              navigateToRecord={navigateToRecord}
            />
          </>
        ) : (
          <>
            {mechanismTable[row].substrate_smiles} <br />
            <SubstrateRowMech
              table={mechanismTable}
              row={row}
              isQuickSearch={isQuickSearch}
              navigateToRecord={navigateToRecord}
            />
          </>
        ),
        mechanismTable[row].product_image ? (
          <>
            <button
              style={{ border: "none", backgroundColor: "rgb(245,245,245)" }}
              disabled={isQuickSearch ? true : mechanismUnclicked ? true : false}
            >
              <InteractiveImage
                src={`/mol_${mechanismTable[row].product}.png`}
                alt={mechanismTable[row].product_smiles}
                className={"responsive"}
                showPopups={showPopups}
                interactiveAdvancedSearch={clickImage}
                isQuickSearch={isQuickSearch}
                parameter={"intermediateSmiles"}
              />
            </button>
            <br />
            <ProductRowMech
              table={mechanismTable}
              row={row}
              isQuickSearch={isQuickSearch}
              navigateToRecord={navigateToRecord}
            />
          </>
        ) : (
          <>
            {mechanismTable[row].product_smiles} <br />
            <ProductRowMech
              table={mechanismTable}
              row={row}
              isQuickSearch={isQuickSearch}
            />
          </>
        ),
        mechanismTable[row].mechanism_type,
      ]);
    }
    return {
      mechanismBody: mechanismBody,
    };
  } catch (err) {
    console.error(err);
  }
}

export default findMechanism;
