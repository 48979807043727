import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import MoleculeNameButton from "../ReactionTable/moleculeNameButton.js";
import SmilesChebiRow from "../ReactionTable/smilesChebiRow.js";
import CopySmilesButton from "../ReactionTable/copySmilesButton.js";
import ExternalLink from "../ReactionTable/externalLink.js";
import ResultInfoText from "../ResultsTable/ResultInfoText.js";


function ProductRowMech({
  table,
  row,
  navigateToRecord,
}) {



  function copySmiles(id) {
    let smiles = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    smiles = smiles.trim();
    elem.value = smiles;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }


  return (
    <>
      {table[row].product_smiles ? (
        <>
          <MoleculeNameButton
            id={"productNameQuickSearch"}
            disabled={true}
          >
            {table[row].product_name === "unnamed_intermediate" ? "" : table[row].product_name}
          </MoleculeNameButton>

          <SmilesChebiRow>
            <CopySmilesButton table={table} row={row} copySmiles={copySmiles} substrateOrProduct={"product"} isMechanism={true}/>
            <p><ResultInfoText text={table[row].product_marts} row={row} fontSize="16px" onClick={() => navigateToRecord(table[row].product_marts)}/></p>
            <li
              style={{ marginRight: 5, position: "relative" }}
              className="chebiLinkMech"
            >
              {table[row].product_chebi ? (
                <>
                  <ExternalLink
                    externalLink={`https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${table[row].product_chebi}`}
                    icon={faExternalLink}
                    idDiv={`goToChebiProductMech${row}`}
                  >
                    Find in ChEBI: {table[row].product_chebi}
                  </ExternalLink>
                </>
              ) : (
                <></>
              )}
            </li>
          </SmilesChebiRow>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ProductRowMech;
