
function translateTerpeneType(type, isEnzyme = false) {
    if (type != "pt" && type != "sesq")
    {
        if (isEnzyme){
            return type + "terpene synthase";
        }
        return type + "terpene";
    }
    else if (type == "pt"){
        if (isEnzyme){
            return "isoprenyl diphosphate synthase";
        }
        return "isoprenyl diphosphate"
    }
    else if (type == "sesq"){
        if (isEnzyme){
            return "sesquiterpene synthase";
        }
        return "sesquiterpene"
    }
    else{
        return type;
    }
}

export default translateTerpeneType;