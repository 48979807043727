
function groupSubstrates(reactions){
    if (reactions.length === 0){
        return [];
    }
    let currentReactionSubstrates = [];
    let currentReactionId = reactions[0].reaction_id;
    let result = [];
    for (let i = 0; i < reactions.length; i++) {
        if (reactions[i].reaction_id !== currentReactionId) {
            currentReactionId = reactions[i].reaction_id;
            let new_reaction = reactions[i-1];
            new_reaction.is_new = false;
            new_reaction.substrates = currentReactionSubstrates;
            let product = {
                molecule_id: new_reaction.product,
                chebi: new_reaction.product_chebi,
                marts: new_reaction.product_marts,
                name: new_reaction.product_name,
                smiles: new_reaction.product_smiles,               
            };
            new_reaction.product_mol = product;
            new_reaction.mechanism_steps = [];
            result.push(new_reaction);
            currentReactionSubstrates = [];
        }
        let substrate = {
            molecule_id: reactions[i].substrate,
            chebi: reactions[i].substrate_chebi,
            marts: reactions[i].substrate_marts,
            name: reactions[i].substrate_name,
            smiles: reactions[i].substrate_smiles
        };
        currentReactionSubstrates.push(substrate);
    }
    let new_reaction = reactions[reactions.length-1];
    new_reaction.is_new = false;
    new_reaction.substrates = currentReactionSubstrates;
    let product = {
        molecule_id: new_reaction.product,
        chebi: new_reaction.product_chebi,
        marts: new_reaction.product_marts,
        name: new_reaction.product_name,
        smiles: new_reaction.product_smiles
    };
    new_reaction.product_mol = product;
    result.push(new_reaction);

    return result;

}

export default groupSubstrates;